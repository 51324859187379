@use "../../../vars/colors" as *;

[dir="rtl"] * {
  .adminHome {
    .logoutBtn {
      border-radius: 0px;
      font-weight: 500;
      font-size: 0.9rem;
      color: whitesmoke;
      background-color: #f51616;
      text-align: right;
      &:hover {
        background-color: #c81010;
      }
    }
    .listItem {
      background-color: transparent !important;
      font-size: 0.8rem;
      font-weight: 500;
      color: whitesmoke;
      text-align: left;
      display: flex;
      align-items: start;
      .listIcon {
        font-size: 1rem !important;
      }
      &:hover {
        background-color: #0f24d4 !important;
        transition: all 0.3s;
      }
    }
    .adminContent {
      background-color: white;
      border-radius: 20px;
      margin: 10px;
    }
    .stationOwnerTitle {
      font-size: 0.85rem;
      text-align: center;
    }
    .traderProfile {
      width: 60px;
      height: 60px;
      background-color: transparent;
      border: 2px solid white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        font-size: 2rem;
      }
    }
    .businessName {
      font-size: 0.9rem;
      font-weight: 700 !important;
    }
    .location {
      h5 {
        font-size: 0.7rem;
        font-weight: 500 !important;
      }
    }
    .changePasswordBtn {
      font-size: 0.6rem;
      font-weight: 700;
      background-color: transparent;
      border: 2px solid white;
      border-radius: 20px !important;
      &:hover {
        background-color: white;
        color: $color_main;
        transition: all 0.2s;
      }
    }
  }

  .menuIcon {
    font-size: 1.4em;
    color: #0f24d4;
  }
}

[dir="ltr"] * {
  .adminHome {
    .logoutBtn {
      border-radius: 0px;
      font-weight: 500;
      font-size: 0.9rem;
      color: whitesmoke;
      background-color: #f51616;
      text-align: left;
      &:hover {
        background-color: #c81010;
      }
    }
    .listItem {
      background-color: transparent !important;
      font-size: 0.75rem;
      font-weight: 500;
      color: whitesmoke;
      text-align: left;
      display: flex;
      align-items: start;
      .listIcon {
        font-size: 1rem !important;
      }
      &:hover {
        background-color: #0f24d4 !important;
        transition: all 0.3s;
      }
    }
    .adminContent {
      background-color: white;
      border-radius: 20px;
      margin: 10px;
    }
    .stationOwnerTitle {
      font-size: 0.85rem;
      text-align: center;
    }
    .traderProfile {
      width: 60px;
      height: 60px;
      background-color: transparent;
      border: 2px solid white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        font-size: 2rem;
      }
    }
    .businessName {
      font-size: 0.9rem;
      font-weight: 700 !important;
    }
    .location {
      h5 {
        font-size: 0.7rem;
        font-weight: 500 !important;
      }
    }
    .changePasswordBtn {
      font-size: 0.6rem;
      font-weight: 700;
      background-color: transparent;
      border: 2px solid white;
      border-radius: 20px !important;
      &:hover {
        background-color: white;
        color: $color_main;
        transition: all 0.2s;
      }
    }
  }

  .menuIcon {
    font-size: 1.4em;
    color: #0f24d4;
  }
}
