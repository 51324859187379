@use "../../../vars/colorsCustomer" as *;

[dir="rtl"] * {
  .customerHomeContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
    }
    .btnRight {
      text-align: left !important;
      button {
        padding: 10px 15px;
        background-color: $color_main;
        font-weight: 400;
        font-size: 0.8rem;
        &:hover {
          background-color: $color_background;
        }
      }
    }
    .balanceTableContent {
      background-color: $color_secondary;
      border-radius: 10px;
      padding: 10px;
      table {
        min-width: 275px;
      }
      thead {
        color: #a4a4a4;
        font-weight: 900;
        font-size: 1rem;
      }
      tbody {
        color: $color_gray_font;
        font-weight: 700;
        font-size: 0.9rem;
      }
    }
    .scanQrContent {
      border: 2px solid $color-main;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      .scanBtn {
        background-color: ghostwhite !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid $color_background;
        min-width: 90px;
        min-height: 90px;
        &:hover {
          background-color: $color_background !important;
          transition: all 0.4s;
          svg {
            color: white;
            font-size: 3.2rem !important;
            transition: all 0.5s;
          }
        }
        svg {
          color: $color_background;
          font-size: 3rem !important;
        }
      }
      .scanTitle {
        color: $color_gray_font;
        font-weight: 0.8rem;
      }
      .verifyBtn {
        background-color: $color_main !important;
        font-weight: 700;
        font-size: 0.8rem;
        &:hover {
          background-color: $color_background;
        }
      }
      .verifyInput {
        border: 2px solid $color-main;
        font-weight: 600;
        text-align: center;
        color: $color_main;
        &:focus {
          box-shadow: none !important;
          border: 2px solid $color-main !important;
        }
      }
    }
  }
}
[dir="ltr"] * {
  .customerHomeContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
    }
    .btnRight {
      text-align: right !important;
      button {
        padding: 10px 15px;
        background-color: $color_main;
        font-weight: 400;
        font-size: 0.8rem;
        &:hover {
          background-color: $color_background;
        }
      }
    }
    .balanceTableContent {
      background-color: $color_secondary;
      border-radius: 10px;
      padding: 10px;
      table {
        min-width: 275px;
      }
      thead {
        color: #a4a4a4;
        font-weight: 900;
        font-size: 1rem;
      }
      tbody {
        color: $color_gray_font;
        font-weight: 700;
        font-size: 0.9rem;
      }
    }
    .scanQrContent {
      border: 2px solid $color-main;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      .scanBtn {
        background-color: ghostwhite !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid $color_background;
        min-width: 90px;
        min-height: 90px;
        &:hover {
          background-color: $color_background !important;
          transition: all 0.4s;
          svg {
            color: white;
            font-size: 3.2rem !important;
            transition: all 0.5s;
          }
        }
        svg {
          color: $color_background;
          font-size: 3rem !important;
        }
      }
      .scanTitle {
        color: $color_gray_font;
        font-weight: 0.7rem !important;
      }
      .verifyBtn {
        background-color: $color_main !important;
        font-weight: 700;
        font-size: 0.8rem;
        &:hover {
          background-color: $color_background;
        }
      }
      .verifyInput {
        border: 2px solid $color-main;
        font-weight: 600;
        text-align: center;
        color: $color_main;
        &:focus {
          box-shadow: none !important;
          border: 2px solid $color-main !important;
        }
      }
    }
  }
}
