@use "../../../vars/colorsCustomer" as *;

* {
  font-family: "Montserrat", "IBMPlexSansArabic" !important;
}
[dir="ltr"] .CustomerLoginContent {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .langBtn {
    position: absolute;
    top: 2%;
    left: 2%;
  }
  .forgetPassLink {
    display: block;
    text-decoration: none;
    color: $color_main;
    font-size: 0.8rem;
    text-align: left;
    text-decoration: underline;
    &:hover {
      color: $color_background;
    }
  }
  .regFormLabel {
    color: $color_main;
  }
  .regFormInput {
    border: 2px solid $color_gray_font;
    font-weight: 700;
    color: $color_gray_font;
    &:focus {
      box-shadow: none;
      border: 2px solid $color_gray_font;
    }
  }
  .phoneStyle {
    text-align: left;
  }
  .regFormPicc {
    width: 80%;
    max-width: 300px;
    height: auto;
  }
  .regFormPic {
    width: 100%;
    max-width: 350px;
    height: auto;
  }
  .cpcRegTitle {
    color: $color_main;
    font-weight: 800 !important;
    text-align: center;
  }
  .btnsGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    .choiceBtn {
      min-height: 100px !important;
      min-width: 100px !important;
      svg {
        font-size: 45px !important;
      }
    }
  }
}
[dir="rtl"] .CustomerLoginContent {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .langBtn {
    position: absolute;
    top: 2%;
    left: 2%;
  }
  .forgetPassLink {
    display: block;
    text-decoration: none;
    color: $color_main;
    font-size: 0.8rem;
    text-align: right;
    text-decoration: underline;
    &:hover {
      color: $color_background;
    }
  }
  .regFormLabel {
    color: $color_main;
  }
  .regFormInput {
    border: 2px solid $color_gray_font;
    font-weight: 700;
    color: $color_gray_font;
    &:focus {
      box-shadow: none;
      border: 2px solid $color_gray_font;
    }
  }
  .phoneStyle {
    text-align: left;
  }
  .regFormPicc {
    width: 80%;
    max-width: 300px;
    height: auto;
  }
  .regFormPic {
    width: 100%;
    max-width: 350px;
    height: auto;
  }
  .cpcRegTitle {
    color: $color_main;
    font-weight: 800 !important;
    text-align: center;
  }
  .btnsGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    .choiceBtn {
      min-height: 100px !important;
      min-width: 100px !important;
      svg {
        font-size: 45px !important;
      }
    }
  }
}

@media (max-width: 512px) {
  * {
    font-size: 0.8rem;
  }
}
