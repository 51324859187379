@use "../../../vars/colors" as *;

[dir="ltr"] * {
  .traderHomeContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
    }
    .btnRight {
      text-align: right;
      button {
        padding: 15px 20px;
        background-color: $color_main;
        font-weight: 400;
        font-size: 0.9rem;
        &:hover {
          background-color: $color_background;
        }
        svg {
          font-size: 17px;
          line-height: 20px;
        }
      }
    }
    .balanceTableContent {
      background-color: $color_secondary;
      border-radius: 10px;
      padding: 10px;
      h6 {
        font-weight: 800;
        font-size: 1rem;
        color: $color_grey_font;
        padding-bottom: 10px;
        text-align: left;
        border-bottom: 1px solid $color_light;
      }
      table {
        min-width: 275px;
      }
      thead {
        color: $color_gray_font;
        font-weight: 700;
        font-size: 1rem;
        .EgpValue {
          font-size: 1.1rem;
          font-weight: 800;
        }
      }
      tbody {
        color: $color_gray_font;
        font-weight: 700;
        font-size: 1.1rem;
        .EgpValue {
          font-size: 1.1rem;
          font-weight: 800;
        }
      }
    }
    .scanQrContent {
      .scanBtn {
        background-color: ghostwhite !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid $color_background;
        min-width: 90px;
        min-height: 90px;
        &:hover {
          background-color: $color_background !important;
          transition: all 0.4s;
          svg {
            color: white;
            font-size: 3.2rem !important;
            transition: all 0.5s;
          }
        }
        svg {
          color: $color_background;
          font-size: 3rem !important;
        }
      }
    }
  }
}

[dir="rtl"] * {
  .traderHomeContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
    }
    .btnRight {
      text-align: left;
      button {
        padding: 15px 20px;
        background-color: $color_main;
        font-weight: 400;
        font-size: 0.9rem;
        &:hover {
          background-color: $color_background;
        }
      }
    }
    .balanceTableContent {
      background-color: $color_secondary;
      border-radius: 10px;
      padding: 10px;
      h6 {
        font-weight: 800;
        font-size: 1rem;
        color: $color_grey_font;
        padding-bottom: 10px;
        text-align: right;
        border-bottom: 1px solid $color_light;
      }
      table {
        min-width: 275px;
      }
      thead {
        color: $color_gray_font;
        font-weight: 700;
        font-size: 1rem;
        .EgpValue {
          font-size: 1.1rem;
          font-weight: 800;
        }
      }
      tbody {
        color: $color_gray_font;
        font-weight: 700;
        font-size: 1.1rem;
        .EgpValue {
          font-size: 1.1rem;
          font-weight: 800;
        }
      }
    }
    .scanQrContent {
      .scanBtn {
        background-color: ghostwhite !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid $color_background;
        min-width: 90px;
        min-height: 90px;
        &:hover {
          background-color: $color_background !important;
          transition: all 0.4s;
          svg {
            color: white;
            font-size: 3.2rem !important;
            transition: all 0.5s;
          }
        }
        svg {
          color: $color_background;
          font-size: 3rem !important;
        }
      }
    }
  }
}
