@use "../../../../vars/colors" as *;

.ViewShopsContent {
  text-align: left;
  .bgGrey {
    background-color: $color_secondary;
    border-radius: 15px;
    .infoContent {
      h5 {
        color: $color_grey_font;
        font-weight: 700;
        font-size: 1.1rem;
      }
      p,
      span {
        color: $color_grey_font;
        font-weight: 500;
        font-size: 0.75rem;
      }
    }
    .btnRegisterGroup {
      .registerBtn {
        padding: 5px 20px;
        background-color: $color_main;
        font-weight: 600;
        font-size: 0.8rem;
        &:hover {
          background-color: $color_background;
        }
      }
    }
  }
  .shopTitle {
    font-weight: 700;
    font-size: 1.2rem;
    color: $color_main;
    text-align: left;
  }
}
