@use "../../../../vars/colorsCustomer" as *;

[dir="rtl"] .StationsDetailsContent {
  .teamTitle {
    font-weight: 700;
    font-size: 1.2rem;
    color: $color_main;
    text-align: right;
    .backBtn {
      rotate: 180deg;
      cursor: pointer;
    }
  }
  .cashoutSelect {
    font-weight: 600;
    font-size: 0.9rem;
    background-color: $color_main;
    color: white;
    &:focus {
      box-shadow: none;
      border: none;
    }
  }
  .cashoutSelect * {
  }
  .stationsDetailsContent {
    ul {
      list-style-type: square;
      li {
        font-weight: 800;
        font-size: 0.9rem;
        color: $color_gray_font;
      }
    }
  }
}

[dir="ltr"] .StationsDetailsContent {
  .teamTitle {
    font-weight: 700;
    font-size: 1.2rem;
    color: $color_main;
    text-align: left;
    .backBtn {
      rotate: 180deg;
      cursor: pointer;
    }
  }
  .cashoutSelect {
    font-weight: 600;
    font-size: 0.9rem;
    background-color: $color_main;
    color: white;
    &:focus {
      box-shadow: none;
      border: none;
    }
  }
  .stationsDetailsContent {
    ul {
      list-style-type: square;
      li {
        font-weight: 800;
        font-size: 0.9rem;
        color: $color_gray_font;
      }
    }
  }
}
