@use "../../../vars/colors" as *;

[dir="rtl"] .OwnerLoginContent {
  min-height: 100vh;
  background-color: white;
  position: relative;
  .langBtn {
    position: absolute;
    top: 2%;
    left: 2%;
  }

  .cpcLogo {
    width: 100%;
    max-width: 120px;
    height: auto;
  }
  .cpcRegTitle {
    text-align: center;
    color: $color_main;
  }
  .loginFormContent {
    text-align: right;
    border: 2px solid $color_main;
    border-radius: 15px;
    .forgetPassLink {
      display: block;
      text-decoration: none;
      color: $color_main;
      font-size: 0.9rem;
      text-align: right;
      text-decoration: underline;
      &:hover {
        color: $color_background;
      }
    }
    .loginLabel {
      font-weight: 600;
      color: $color_main;
    }
    .loginInput {
      min-height: 50px;
      border: 1px solid $color_main;
      font-weight: 400;
      color: $color_background;
      &:focus {
        text-align: left;
        outline: none;
        box-shadow: none;
      }
    }
    .loginBtn {
      padding: 10px 40px;
      background-color: $color_main;
      font-weight: 400;
      font-size: 1rem;
      &:hover {
        background-color: $color_background;
      }
    }
  }
}
[dir="ltr"] .OwnerLoginContent {
  min-height: 100vh;
  background-color: white;
  position: relative;
  .langBtn {
    position: absolute;
    top: 2%;
    left: 2%;
  }
  .cpcLogo {
    width: 100%;
    max-width: 120px;
    height: auto;
  }
  .cpcRegTitle {
    text-align: center;
    color: $color_main;
  }
  .loginFormContent {
    text-align: left;
    border: 2px solid $color_main;
    border-radius: 15px;
    .forgetPassLink {
      display: block;
      text-decoration: none;
      color: $color_main;
      font-size: 0.9rem;
      text-align: right;
      text-decoration: underline;
      &:hover {
        color: $color_background;
      }
    }
    .loginLabel {
      font-weight: 600;
      color: $color_main;
    }
    .loginInput {
      min-height: 50px;
      border: 1px solid $color_main;
      font-weight: 400;
      color: $color_background;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .loginBtn {
      padding: 10px 40px;
      background-color: $color_main;
      font-weight: 400;
      font-size: 1rem;
      &:hover {
        background-color: $color_background;
      }
    }
  }
}
