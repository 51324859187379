@use "./../../../vars/_colors.scss" as *;

[dir="rtl"] * {
  .qrcodesContent {
    .shopTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
    }
    .bgGrey {
      background-color: #005dff;
      border-radius: 15px;
      .infoContent {
        h5 {
          color: white;
          font-weight: 700;
          font-size: 1.1rem;
        }
        p,
        span {
          color: white;
          font-weight: 500;
          font-size: 0.7rem;
        }
        .activatorChar {
          width: 80px;
          img {
            border: 3px solid $color_main;
            border-radius: 50%;
            width: 100%;
            height: auto;
          }
        }
        .activatorTitle {
          text-align: left;
          display: flex;
          flex-direction: column;
          text-align: start;
          justify-content: center;
          h4 {
            font-size: 0.8rem;
            font-weight: 600;
            color: white;
          }
          h5 {
            font-size: 0.8rem;
            font-weight: 600;
            color: white;
          }
          h6 {
            font-size: 0.8rem;
            font-weight: 600;
            color: white;
          }
        }
      }
      .editBtn {
        padding: 5px 30px;
        background-color: white;
        font-weight: 500;
        font-size: 2.5em;
        width: 100%;
        height: 100%;
        min-height: 60px;
        color: #005dff;
        svg {
          font-size: 25px;
        }
        &:hover {
          background-color: ghostwhite;
        }
      }
    }
  }
  .qrModal {
    background-color: white !important;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h5 {
      color: $color_main;
      font-weight: 700;
      font-size: 1.1rem;
    }
    .closeBtn {
      padding: 5px 25px;
      background-color: $color_gray_font;
      font-weight: 600;
      &:hover {
        background-color: $color_grey_font;
      }
    }
  }

  div:has(.qrModal) {
    border-radius: 20px !important;
  }
}

[dir="ltr"] * {
  .qrcodesContent {
    .shopTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
    }
    .bgGrey {
      background-color: #005dff;
      border-radius: 15px;
      .infoContent {
        h5 {
          color: white;
          font-weight: 700;
          font-size: 1.1rem;
        }
        p,
        span {
          color: white;
          font-weight: 500;
          font-size: 0.7rem;
        }
        .activatorChar {
          width: 80px;
          img {
            border: 3px solid $color_main;
            border-radius: 50%;
            width: 100%;
            height: auto;
          }
        }
        .activatorTitle {
          text-align: left;
          display: flex;
          flex-direction: column;
          text-align: start;
          justify-content: center;
          h4 {
            font-size: 0.8rem;
            font-weight: 600;
            color: white;
          }
          h5 {
            font-size: 0.8rem;
            font-weight: 600;
            color: white;
          }
          h6 {
            font-size: 0.8rem;
            font-weight: 600;
            color: white;
          }
        }
      }
      .editBtn {
        padding: 5px 30px;
        background-color: white;
        font-weight: 500;
        font-size: 2.5em;
        width: 100%;
        height: 100%;
        min-height: 60px;
        color: #005dff;
        svg {
          font-size: 25px;
        }
        &:hover {
          background-color: ghostwhite;
        }
      }
    }
  }
  .qrModal {
    background-color: white !important;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h5 {
      color: $color_main;
      font-weight: 700;
      font-size: 1.1rem;
    }
    .closeBtn {
      padding: 5px 25px;
      background-color: $color_gray_font;
      font-weight: 600;
      &:hover {
        background-color: $color_grey_font;
      }
    }
  }

  div:has(.qrModal) {
    border-radius: 20px !important;
  }
}
