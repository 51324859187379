@use "../../../../../../vars/colors" as *;

.giftPointContent {
}
.giftPointBody {
  h4 {
    font-size: 0.9rem;
    font-weight: 800;
    color: $color-main;
  }
  p {
    font-size: 0.8rem;
    font-weight: 700;
    color: $color_gray_font;
    text-align: center;
  }
  .okBtn {
    background-color: $color-main;
    font-size: 0.7rem;
    font-weight: 600;
    color: white;
    padding: 5px 35px;
    &:hover {
      background-color: $color-background;
      color: white;
    }
  }
  .cashNotes {
    background-color: #DCE2FE;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    ul {
      li {
        color: $color-main;
        font-weight: 500;
        font-size: 0.8rem;
      }
    }
  }
}
