@use "../../../../vars/colors" as *;

[dir="rtl"] * {
  .assignedContent {
    .bgGrey {
      background-color: #dce2fe;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      min-height: 127px;
      .activatorInfoContent {
        height: 100%;
        flex-wrap: wrap;
        img {
          width: 80px;
          height: auto;
          border: 2px solid $color_gray_font;
          border-radius: 50%;
        }
        .activatorInfo {
          h5 {
            color: $color_main;
            font-size: 1rem;
            font-weight: 800;
          }
          p {
            color: $color_main;
            font-size: 0.8rem;
            font-weight: 500;
          }
        }
      }
      .activatorBtnContent {
        .editBtn {
          padding: 5px 30px;
          background-color: $color_main;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_background;
          }
        }
        .detailsBtn {
          padding: 5px 30px;
          background-color: #748afc;
          font-weight: 500;
          font-size: 0.8rem;
          border: none !important;
          &:hover {
            background-color: $color_main;
          }
        }
      }
      .activatedStatus {
        font-size: 0.8rem !important;
      }
    }
    .bgActivate {
      background-color: $color_secondary;
      .activatorInfoContent {
        height: 100%;
        flex-wrap: wrap;
        img {
          width: 80px;
          height: auto;
          border: 2px solid $color_gray_font;
          border-radius: 50%;
        }
        .activatorInfo {
          h5 {
            color: $color_grey_font;
            font-size: 1rem;
            font-weight: 800;
          }
          p {
            color: $color_grey_font;
            font-size: 0.8rem;
            font-weight: 500;
          }
        }
      }
      .activatorBtnContent {
        .editBtn {
          padding: 5px 30px;
          background-color: $color_main;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_background;
          }
        }
        .deactivateBtn {
          color: #ffffff;
          padding: 5px 30px;
          background-color: #ffa500;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: #e19303;
          }
        }
        .detailsBtn {
          padding: 5px 30px;
          background-color: $color_gray_font;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_grey_font;
          }
        }
      }
    }
    .bgNottActivee {
      background-color: #b4b4b4;
      .activatorInfoContent {
        height: 100%;
        flex-wrap: wrap;
        img {
          width: 80px;
          height: auto;
          border: 2px solid $color_gray_font;
          border-radius: 50%;
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
        }
        .activatorInfo {
          h5 {
            color: $color_grey_font;
            font-size: 1rem;
            font-weight: 800;
          }
          p {
            color: $color_grey_font;
            font-size: 0.8rem;
            font-weight: 500;
          }
        }
      }
      .activatorBtnContent {
        .editBtn {
          padding: 5px 30px;
          background-color: $color_main;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_background;
          }
        }
        .deactivateBtn {
          color: #ffffff;
          padding: 5px 30px;
          background-color: #ffa500;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: #e19303;
          }
        }
        .detailsBtn {
          padding: 5px 30px;
          background-color: $color_gray_font;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_grey_font;
          }
        }
      }
    }
    .activatorLabel {
      color: $color_grey_font;
      font-size: 0.8rem;
      font-weight: 700;
      span {
        color: $color_main !important;
        font-size: 0.8rem;
        font-weight: 800;
      }
    }
    .activatorSelect {
      text-align: left;
      background-color: $color_main;
      color: white;
      font-size: 0.8rem;
      & option:first-of-type {
        text-align: right !important;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .saveBtn {
      padding: 5px 30px;
      background-color: $color_main;
      font-size: 0.8rem;
      font-weight: 500;
      &:hover {
        background-color: $color_background;
      }
    }
  }
}

[dir="ltr"] * {
  .assignedContent {
    .bgGrey {
      background-color: #dce2fe;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      min-height: 127px;
      .activatorInfoContent {
        height: 100%;
        flex-wrap: wrap;
        img {
          width: 80px;
          height: auto;
          border: 2px solid $color_gray_font;
          border-radius: 50%;
        }
        .activatorInfo {
          h5 {
            color: $color_main;
            font-size: 1rem;
            font-weight: 800;
          }
          p {
            color: $color_main;
            font-size: 0.8rem;
            font-weight: 500;
          }
        }
      }
      .activatorBtnContent {
        .editBtn {
          padding: 5px 30px;
          background-color: $color_main;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_background;
          }
        }
        .deactivateBtn {
          color: #ffffff;
          padding: 5px 30px;
          background-color: #ffa500;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: #e19303;
          }
        }
        .detailsBtn {
          padding: 5px 30px;
          background-color: #748afc;
          font-weight: 500;
          font-size: 0.8rem;
          border: none !important;
          &:hover {
            background-color: $color_main;
          }
        }
      }
      .activatedStatus {
        font-size: 0.8rem !important;
      }
    }
    .bgActivate {
      background-color: $color_secondary;
      .activatorInfoContent {
        height: 100%;
        flex-wrap: wrap;
        img {
          width: 80px;
          height: auto;
          border: 2px solid $color_gray_font;
          border-radius: 50%;
        }
        .activatorInfo {
          h5 {
            color: $color_grey_font;
            font-size: 1rem;
            font-weight: 800;
          }
          p {
            color: $color_grey_font;
            font-size: 0.8rem;
            font-weight: 500;
          }
        }
      }
      .activatorBtnContent {
        .editBtn {
          padding: 5px 30px;
          background-color: $color_main;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_background;
          }
        }
        .deactivateBtn {
          color: #ffffff;
          padding: 5px 30px;
          background-color: #ffa500;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: #e19303;
          }
        }
        .detailsBtn {
          padding: 5px 30px;
          background-color: $color_gray_font;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_grey_font;
          }
        }
      }
    }
    .bgNottActivee {
      background-color: #b4b4b4;
      .activatorInfoContent {
        height: 100%;
        flex-wrap: wrap;
        img {
          width: 80px;
          height: auto;
          border: 2px solid $color_gray_font;
          border-radius: 50%;
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
        }
        .activatorInfo {
          h5 {
            color: $color_grey_font;
            font-size: 1rem;
            font-weight: 800;
          }
          p {
            color: $color_grey_font;
            font-size: 0.8rem;
            font-weight: 500;
          }
        }
      }
      .activatorBtnContent {
        .editBtn {
          padding: 5px 30px;
          background-color: $color_main;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_background;
          }
        }
        .deactivateBtn {
          color: #ffffff;
          padding: 5px 30px;
          background-color: #ffa500;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: #e19303;
          }
        }
        .detailsBtn {
          padding: 5px 30px;
          background-color: $color_gray_font;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_grey_font;
          }
        }
      }
    }
    .activatorLabel {
      color: $color_grey_font;
      font-size: 0.8rem;
      font-weight: 700;
      span {
        color: $color_main !important;
        font-size: 0.8rem;
        font-weight: 800;
      }
    }
    .activatorSelect {
      text-align: left;
      background-color: $color_main;
      color: white;
      font-size: 0.8rem;
      &:focus {
        box-shadow: none;
      }
    }
    .saveBtn {
      padding: 5px 30px;
      background-color: $color_main;
      font-size: 0.8rem;
      font-weight: 500;
      &:hover {
        background-color: $color_background;
      }
    }
  }
}

@media (max-width: 445px) {
  .assignedContent {
    .bgGrey {
      .activatorBtnContent {
        .editBtn {
          margin-bottom: 7px !important;
        }
        .editBtn,
        .detailsBtn {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 445px) {
  .assignedContent {
    .bgGrey {
      .activatorBtnContent {
        .editBtn {
          margin-right: 7px !important;
        }
      }
    }
  }
}

.governFilterInput {
  background-color: #a5b2f8;
  color: white;
  padding: 5px 10px;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    background-color: $color_main;
  }
}

.governInput {
  &:checked + label {
    background-color: $color_background;
    color: whitesmoke;
  }
}
