@use "../../../../vars/colors" as *;

.uploadBatchContent {
  .bgGrey {
    background-color: $color_secondary;
    border-radius: 10px;
    .uploadBatchLabel {
      background-color: $color_main;
      padding: 10px 30px;
      border-radius: 10px;
      color: whitesmoke;
      font-size: 0.8rem;
      cursor: pointer;
      &:hover {
        background-color: $color_background;
      }
    }
    .message{
        font-size: 0.7rem!important;
        font-weight: 600!important;
    }
    .bgFileResult {
      border-radius: 10px;
      background-color: #dce2fe;
      svg {
        font-size: 30px;
        color: #889cff;
      }
      .fileName {
        font-size: 0.8rem;
        font-weight: 800;
        color: $color_main;
      }
      .progress {
        height: 5px;
        width: 100%;
        background-color: #f8f8ff;
        border-radius: 30px !important;
        .progressBar {
          height: 100%;
          width: 0%;
          border-radius: inherit;
          background-color: $color_main;
          transition: all 0.2s;
        }
      }
    }
  }
}
