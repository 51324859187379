@use "../../../../vars/colors" as *;

.editShopContent {
  text-align: left;
  .teamTitle {
    font-weight: 700;
    font-size: 1.2rem;
    color: $color_main;
    text-align: left;
  }
  .btnRight {
    text-align: right;
    .deleteBtn {
      padding: 10px 40px;
      background-color: #f51616;
      font-weight: 400;
      font-size: 1rem;
      &:hover {
        background-color: #ba0b0b;
      }
    }
  }
  .saveBtn {
    padding: 10px 40px;
    background-color: $color_main;
    font-weight: 400;
    font-size: 1rem;
    &:hover {
      background-color: $color_background;
    }
  }
  .editShopForm {
    .editShopLabel {
      font-weight: 600;
      color: $color_main;
    }
    .editShopInput {
      min-height: 50px;
      border: 1px solid $color_main;
      font-weight: 400;
      color: $color_background;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .shopInfoTitle {
    font-weight: 700;
    font-size: 1.2rem;
    color: $color_main;
    text-align: left;
  }
  .addBranchBtn {
    padding: 5px 20px;
    background-color: $color_main;
    font-weight: 400;
    font-size: 0.8rem;
    &:hover {
      background-color: $color_background;
    }
  }
  .editBranchForm {
    .editShopLabel {
      font-weight: 600;
      color: $color_main;
    }
    .editShopInput {
      min-height: 50px;
      border: 1px solid $color_main;
      font-weight: 400;
      color: $color_background;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .bgBlue {
      background-color: $color_main;
      border-radius: 10px;
      .deleteBranchBtn {
        padding: 5px 20px;
        background-color: #f51616;
        font-weight: 400;
        font-size: 0.8rem;
        &:hover {
          background-color: #ba0b0b;
        }
      }
      .branchBtn{
        padding: 5px 20px;
        background-color: #ffffff;
        color: $color_main;
        font-weight: 500;
        font-size: 0.8rem;
        &:hover{
            background-color: #f2f2f2;
        }
      }
      .editBranchLabel {
        font-weight: 600;
        color: whitesmoke;
        font-size: 0.9rem;
      }
      .editBranchInput {
        min-height: 50px;
        border: 1px solid $color_main;
        font-weight: 400;
        color: $color_background;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}
