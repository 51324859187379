@use "../../../../vars/colors" as *;

[dir="rtl"] * {
  .CustomerFuelContent {
    .redeemCashoutTitle {
      text-align: right;
      color: $color_main;
      font-size: 0.8rem;
      font-weight: 500;
    }
    .savedCarNote {
      text-align: right;
      color: $color_main;
      font-size: 0.8rem;
      font-weight: 700;
    }
    .cashoutChoiceBtn {
      padding: 5px 35px;
      background-color: white !important;
      border: none !important;
      font-weight: 500;
      font-size: 0.8rem;
      white-space: nowrap;
      color: $color_main !important;
      &:hover {
        background-color: transparent !important;
        color: $color_main !important;
        font-weight: 700;
        transition: all 0.1s;
      }
    }
    .cashoutChoiceBtnActive {
      font-weight: 700;
      border-bottom: 2px solid $color_main !important;
    }

    .cashoutInputContent {
      position: relative;
      background-color: #dce2fe;
      border-radius: 10px;
      .termsBtn {
        text-decoration: underline !important;
        text-decoration-color: $color-main !important;
        -moz-text-decoration-color: $color-main !important;
        small {
          &:hover {
            font-weight: 600 !important;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
            -moz-transition: all 0.2s;
            -ms-transition: all 0.2s;
            -o-transition: all 0.2s;
          }
        }
      }
      label {
        font-weight: 700;
        color: $color_main;
        font-size: 0.9rem;
      }
      small {
        color: $color_main;
        font-size: 0.7rem;
        font-weight: 500;
      }
      .cashoutSelect {
        font-weight: 600;
        font-size: 0.8rem;
        background-color: $color_main;
        color: white;
        &:focus {
          box-shadow: none;
          border: none;
        }
      }
      .cashoutInput {
        border: 1px solid $color_background;
        font-weight: 600;
        text-align: center;
        color: $color_main;
        &:focus {
          box-shadow: none !important;
          border: 2px solid $color_background !important;
        }
      }
      .carplateLetterStyle {
        letter-spacing: 10px;
      }
      .scanStationContent {
        padding: 10px 20px;
        background-color: $color-main;
        font-size: 0.8rem;
        color: white;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        cursor: pointer;
        &:hover {
          background-color: $color_background;
        }
      }
      .stationInfoContent {
        padding: 10px 20px;
        background-color: #b5c1ff;
        font-size: 0.8rem;
        font-weight: 700;
        color: $color-main;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
      }
    }
    .notActiveContent {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(242, 242, 242, 0.6) !important;
      border-radius: 10px;
      z-index: 10;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }
    .saveBtn {
      background-color: $color_main !important;
      font-weight: 700;
      &:hover {
        background-color: $color_background;
      }
    }
    .fuelChoices {
      .fuelCohicesBtn {
        padding: 5px 30px;
        font-size: 0.7rem;
        background-color: $color-main;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        &:hover {
          background-color: $color_background;
        }
      }
    }
    .valuesContent {
      max-height: 60px;
      overflow-x: auto;
      border: 1px solid #b5c1ff;
      border-radius: 10px;
      display: flex;
      justify-content: start;
      align-items: center;
      .fuelValue {
        &:checked + label {
          background-color: $color_background;
          color: white;
        }
      }
      /* width */
      &::-webkit-scrollbar {
        width: 10px;
        height: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #dce2fe;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $color_main;
        border-radius: 15px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $color_background;
      }
      .fuelValueBtn {
        text-align: center;
        white-space: nowrap;
        padding: 5px 20px;
        border-radius: 20px;
        background-color: #b5c1ff;
        color: $color_main;
        font-size: 0.7rem;
        font-weight: 600;
        &:hover {
          background-color: $color_main;
          color: white;
          cursor: pointer;
        }
      }
    }
    .totalValuepara {
      line-height: 2rem;
      .finalValueStyle {
        background-color: #b5c1ff;
        font-size: 0.8rem;
        font-weight: 700;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
      }
    }
    .stationNameStyle {
      padding: 10px 40px;
      background-color: #b5c1ff;
      font-size: 0.8rem;
      font-weight: 600;
      border-radius: 12px;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      -ms-border-radius: 12px;
      -o-border-radius: 12px;
    }
    .walletChoiceBtn {
      padding: 5px 30px;
      font-size: 0.7rem;
      background-color: $color-main;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      &:hover {
        background-color: $color_background;
      }
    }
    .walletnotActiveBtn {
      color: $color-main;
      padding: 5px 30px;
      font-size: 0.7rem;
      background-color: #b5c1ff;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      &:hover {
        color: $color-main;
      }
    }
    .redeemBtnContent {
      background-color: white;
      border: 2px solid transparent;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      .redeemBtnText {
        background-color: transparent !important;
        outline: none !important;
        border: none !important;
        .redeemBtn {
          padding: 5px 15px;
          background-color: $color-main;
          font-size: 0.8rem;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          &:hover {
            color: white;
            background-color: $color_background;
          }
          &:disabled {
            background-color: $color_background;
            color: white;
          }
        }
        .pasteBtn {
          padding: 5px 15px;
          background-color: $color_dark_red;
          font-size: 0.8rem;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          &:hover {
            color: white;
            background-color: #cb0707;
          }
          &:disabled {
            background-color: #df4f4f;
            color: white;
          }
        }
      }
      .redeemBtnInput {
        background-color: transparent;
        border: none;
        outline: none;
        color: $color-main;
        font-weight: 700;
        font-size: 0.8rem;
        text-align: center;
        outline: none;
        box-shadow: none;
        border: none;
        &:read-only {
          background-color: transparent;
          border: none;
          outline: none;
          color: $color-main;
          font-weight: 700;
          font-size: 0.8rem;
          text-align: center;
          outline: none;
          box-shadow: none;
          border: none;
          opacity: 0.6;
        }
        &:focus {
          color: $color-main;
          font-weight: 700;
          font-size: 0.8rem;
          text-align: center;
          outline: none;
          box-shadow: none;
          border: none;
        }
      }
    }
  }
}
[dir="ltr"] * {
  .CustomerFuelContent {
    .redeemCashoutTitle {
      text-align: left;
      color: $color_main;
      font-size: 0.8rem;
      font-weight: 500;
    }
    .savedCarNote {
      text-align: left;
      color: $color_main;
      font-size: 0.8rem;
      font-weight: 700;
    }
    .cashoutChoiceBtn {
      padding: 5px 35px;
      background-color: white !important;
      border: none !important;
      font-weight: 500;
      font-size: 0.8rem;
      white-space: nowrap;
      color: $color_main !important;
      &:hover {
        background-color: transparent !important;
        color: $color_main !important;
        font-weight: 700;
        transition: all 0.1s;
      }
    }
    .cashoutChoiceBtnActive {
      font-weight: 700;
      border-bottom: 2px solid $color_main !important;
    }
    .cashoutInputContent {
      position: relative;
      background-color: #dce2fe;
      border-radius: 10px;
      .termsBtn {
        text-decoration: underline !important;
        text-decoration-color: $color-main !important;
        -moz-text-decoration-color: $color-main !important;
        small {
          &:hover {
            font-weight: 600 !important;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
            -moz-transition: all 0.2s;
            -ms-transition: all 0.2s;
            -o-transition: all 0.2s;
          }
        }
      }
      label {
        font-weight: 700;
        color: $color_main;
        font-size: 0.9rem;
      }
      small {
        color: $color_main;
        font-size: 0.7rem;
        font-weight: 500;
      }
      .cashoutSelect {
        font-weight: 600;
        font-size: 0.8rem;
        background-color: $color_main;
        color: white;
        &:focus {
          box-shadow: none;
          border: none;
        }
      }
      .cashoutInput {
        border: 1px solid $color_background;
        font-weight: 600;
        text-align: center;
        color: $color_main;
        &:focus {
          box-shadow: none !important;
          border: 2px solid $color_background !important;
        }
      }
      .carplateLetterStyle {
        letter-spacing: 10px;
      }
      .scanStationContent {
        padding: 10px 20px;
        background-color: $color-main;
        font-size: 0.8rem;
        color: white;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        cursor: pointer;
        &:hover {
          background-color: $color_background;
        }
      }
      .stationInfoContent {
        padding: 10px 20px;
        background-color: #b5c1ff;
        font-size: 0.8rem;
        font-weight: 700;
        color: $color-main;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
      }
    }
    .notActiveContent {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(242, 242, 242, 0.6) !important;
      border-radius: 10px;
      z-index: 10;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }
    .sav .saveBtn {
      background-color: $color_main !important;
      font-weight: 700;
      &:hover {
        background-color: $color_background;
      }
    }
    .fuelChoices {
      .fuelCohicesBtn {
        padding: 5px 30px;
        font-size: 0.7rem;
        background-color: $color-main;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        &:hover {
          background-color: $color_background;
        }
      }
    }
    .valuesContent {
      max-height: 60px;
      overflow-x: auto;
      border: 1px solid #b5c1ff;
      border-radius: 10px;
      display: flex;
      justify-content: start;
      align-items: center;
      .fuelValue {
        &:checked + label {
          background-color: $color_background;
          color: white;
        }
      }
      /* width */
      &::-webkit-scrollbar {
        width: 10px;
        height: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #dce2fe;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $color_main;
        border-radius: 15px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $color_background;
      }
      .fuelValueBtn {
        text-align: center;
        white-space: nowrap;
        padding: 5px 20px;
        border-radius: 20px;
        background-color: #b5c1ff;
        color: $color_main;
        font-size: 0.7rem;
        font-weight: 600;
        &:hover {
          background-color: $color_main;
          color: white;
          cursor: pointer;
        }
      }
    }
    .totalValuepara {
      line-height: 2rem;
      .finalValueStyle {
        background-color: #b5c1ff;
        font-size: 0.8rem;
        font-weight: 700;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
      }
    }
    .stationNameStyle {
      background-color: #b5c1ff;
      font-size: 0.8rem;
      font-weight: 600;
      border-radius: 12px;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      -ms-border-radius: 12px;
      -o-border-radius: 12px;
    }
    .walletChoiceBtn {
      padding: 5px 30px;
      font-size: 0.7rem;
      background-color: $color-main;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      &:hover {
        background-color: $color_background;
      }
    }
    .walletnotActiveBtn {
      color: $color-main;
      padding: 5px 30px;
      font-size: 0.7rem;
      background-color: #b5c1ff;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      &:hover {
        background-color: $color_background;
        color: $color-main;
      }
    }
    .redeemBtnContent {
      background-color: white;
      border: 2px solid $color-main;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      .redeemBtnText {
        background-color: transparent !important;
        outline: none !important;
        border: none !important;
        .redeemBtn {
          padding: 5px 15px;
          background-color: $color-main;
          font-size: 0.8rem;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          &:hover {
            color: white;
            background-color: $color_background;
          }
          &:disabled {
            background-color: $color_background;
            color: white;
          }
        }
        .pasteBtn {
          padding: 5px 15px;
          background-color: $color_dark_red;
          font-size: 0.8rem;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          &:hover {
            color: white;
            background-color: #cb0707;
          }
          &:disabled {
            background-color: #df4f4f;
            color: white;
          }
        }
      }
      .redeemBtnInput {
        background-color: transparent;
        border: none;
        outline: none;
        color: $color-main;
        font-weight: 700;
        font-size: 0.8rem;
        text-align: center;
        outline: none;
        box-shadow: none;
        border: none;
        &:read-only {
          background-color: transparent;
          border: none;
          outline: none;
          color: $color-main;
          font-weight: 700;
          font-size: 0.8rem;
          text-align: center;
          outline: none;
          box-shadow: none;
          border: none;
          opacity: 0.6;
        }
        &:focus {
          color: $color-main;
          font-weight: 700;
          font-size: 0.8rem;
          text-align: center;
          outline: none;
          box-shadow: none;
          border: none;
        }
      }
    }
  }
}
