@use "../../../../vars/colors" as *;

[dir="rtl"] * {
  .listingShopsContent {
    .bgGrey {
      background-color: $color_secondary;
      border-radius: 15px;
      .infoContent {
        h5 {
          color: $color_main;
          font-weight: 700;
          font-size: 1.1rem;
        }
        p,
        span {
          color: $color_grey_font;
          font-weight: 500;
          font-size: 0.7rem;
        }
        .activatorChar {
          width: 80px;
          img {
            border: 3px solid $color_main;
            border-radius: 50%;
            width: 100%;
            height: auto;
          }
        }
        .activatorTitle {
          text-align: left;
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          h4 {
            font-size: 0.8rem;
            font-weight: 600;
            color: $color_main;
          }
          h5 {
            font-size: 0.8rem;
            font-weight: 600;
            color: $color_main;
          }
          h6 {
            font-size: 0.8rem;
            font-weight: 600;
            color: $color_main;
          }
        }
      }
      .editBtn {
        padding: 5px 30px;
        background-color: $color_main;
        font-weight: 500;
        font-size: 0.8rem;
        width: 100%;
        height: 100%;
        min-height: 60px;
        &:hover {
          background-color: $color_background;
        }
      }
      .btnEditGroup {
        .viewBtn {
          padding: 5px 30px;
          background-color: $color_gray_font;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_grey_font;
          }
        }
        .detailsBtn {
          padding: 5px 30px;
          background-color: $color_gray_font;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_grey_font;
          }
        }
      }
    }
    .activatorLabel {
      color: $color_main;
      font-size: 0.9rem;
      font-weight: 700;
    }
    .activatorSelect {
      background-color: $color_main;
      color: white;
      font-size: 0.8rem;
      &:focus {
        box-shadow: none;
      }
    }
  }

  @media (max-width: 426px) {
    .editBtn,
    .viewBtn {
      width: 100% !important;
    }
    .editBtn {
      margin-bottom: 7px;
    }
  }
}
[dir="ltr"] * {
  .listingShopsContent {
    .bgGrey {
      background-color: $color_secondary;
      border-radius: 15px;
      .infoContent {
        h5 {
          color: $color_main;
          font-weight: 700;
          font-size: 1.1rem;
        }
        p,
        span {
          color: $color_grey_font;
          font-weight: 500;
          font-size: 0.7rem;
        }
        .activatorChar {
          width: 80px;
          img {
            border: 3px solid $color_main;
            border-radius: 50%;
            width: 100%;
            height: auto;
          }
        }
        .activatorTitle {
          text-align: left;
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          h4 {
            font-size: 0.8rem;
            font-weight: 600;
            color: $color_main;
          }
          h5 {
            font-size: 0.8rem;
            font-weight: 600;
            color: $color_main;
          }
          h6 {
            font-size: 0.8rem;
            font-weight: 600;
            color: $color_main;
          }
        }
      }
      .editBtn {
        padding: 5px 30px;
        background-color: $color_main;
        font-weight: 500;
        font-size: 0.8rem;
        width: 100%;
        height: 100%;
        min-height: 60px;
        &:hover {
          background-color: $color_background;
        }
      }
      .btnEditGroup {
        .viewBtn {
          padding: 5px 30px;
          background-color: $color_gray_font;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_grey_font;
          }
        }
        .detailsBtn {
          padding: 5px 30px;
          background-color: $color_gray_font;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_grey_font;
          }
        }
      }
    }
    .activatorLabel {
      color: $color_main;
      font-size: 0.9rem;
      font-weight: 700;
    }
    .activatorSelect {
      background-color: $color_main;
      color: white;
      font-size: 0.8rem;
      &:focus {
        box-shadow: none;
      }
    }
  }

  @media (max-width: 426px) {
    .editBtn,
    .viewBtn {
      width: 100% !important;
    }
    .editBtn {
      margin-bottom: 7px;
    }
  }
}
