@use "../../../../../../vars/colors" as *;

.createCashout {
  .saveBtn {
    padding: 10px 40px;
    background-color: $color_main;
    font-weight: 400;
    font-size: 1rem;
    &:hover {
      background-color: $color_background;
    }
  }
  .formGroupCashout {
    background-color: #dce2fe;
    border-radius: 10px;
    h4{
        font-size: 0.9rem;
        color: $color_main;
        font-weight: 700;
    }
    .formLabel {
      color: $color_main;
      font-weight: 700;
      font-size: 0.8rem;
    }
    .inputGroupForm {
      outline: none;
      border: 2px solid $color_main;
      background-color: white;
      border-radius: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      input {
        border: none;
        outline: none;
        text-align: center;
        font-weight: 700;
        color: $color_main;
        &:focus {
          outline: none;
          box-shadow: none;
          text-align: center;
          font-weight: 700;
          color: $color_main;
        }
        &:disabled{
          background-color: transparent!important;
        }
      }
      span {
        outline: none;
        border: none;
        background-color: transparent !important;
        border-left: 3px solid $color_main;
        font-weight: 700;
        font-size: 0.7rem;
        color: $color_main;
      }
    }
    .formGroupCashoutNote {
      color: $color_main;
      font-weight: 500;
      font-size: 0.75rem;
    }
  }
}
