@use "../../../../../vars/colorsCustomer" as *;

.customerBankCardContent {
  .cashoutInputContent {
    background-color: #ffe6e6;
    border-radius: 10px;
    .termsBtn {
      text-decoration: underline !important;
      text-decoration-color: $color-main !important;
      -moz-text-decoration-color: $color-main !important;
      small {
        &:hover {
          font-weight: 600 !important;
          transition: all 0.2s;
          -webkit-transition: all 0.2s;
          -moz-transition: all 0.2s;
          -ms-transition: all 0.2s;
          -o-transition: all 0.2s;
        }
      }
    }
    label {
      font-weight: 700;
      color: $color_main;
      font-size: 0.9rem;
    }
    small {
      color: $color_main;
      font-size: 0.7rem;
      font-weight: 500;
    }
    .cashoutSelect {
      font-weight: 600;
      font-size: 0.8rem;
      background-color: $color_main;
      color: white;
      &:focus {
        box-shadow: none;
        border: none;
      }
    }
    .cashoutInput {
      border: 1px solid $color_background;
      font-weight: 600;
      text-align: center;
      color: $color_main;
      &:focus {
        box-shadow: none !important;
        border: 2px solid $color_background !important;
      }
    }
    .alertMessageContent {
      background-color: $color-main;
      color: white;
      font-size: 0.7rem;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }
    .cardNumberInput {
      max-width: 100px;
    }
  }
  .saveBtn {
    background-color: $color_main !important;
    font-weight: 700;
    &:hover {
      background-color: $color_background;
    }
  }
  .valueStyle {
    padding: 5px 15px;
    background-color: #fcc1c1;
    font-weight: 600;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .totalValuepara {
    line-height: 2rem;
    .finalValueStyle {
      background-color: #fcc1c1;
      font-size: 0.8rem;
      font-weight: 700;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }
  }
  .walletChoiceBtn {
    padding: 5px 30px;
    font-size: 0.7rem;
    background-color: $color-main;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    &:hover {
      background-color: $color_background;
    }
  }
}
