.HelpWhatsappContent {
  .chatBtn {
    background-color: #128c7e;
    font-weight: 700;
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    transition: all 0.6s ease-in-out;
    -webkit-transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -ms-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
    span {
      display: none;
      font-size: 0.65rem;
      transition: all 0.6s ease-in-out;
      -webkit-transition: all 0.6s ease-in-out;
      -moz-transition: all 0.6s ease-in-out;
      -ms-transition: all 0.6s ease-in-out;
      -o-transition: all 0.6s ease-in-out;
    }
    &:hover {
      background-color: #09645a;
      width: 250px;
      .whatsIcon {
        font-size: 20px;
      }
      span {
        display: inline-block;
      }
    }
    .whatsIcon {
      font-size: 20px;
    }
  }
}
