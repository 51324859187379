@use "../../../../vars/colors" as *;

div:has(> .qrCodeModal) {
  background-color: $color-background;
  border-radius: 10px !important;
}

.qrVideo {
  width: 100%;
  height: 100%;
  background-color: black;
  border-radius: 15px;
  border: 2px solid $color-main;
}
.qrResult {
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: $color-main;
  text-align: center;
  font-weight: 700;
  color: white;
}
