@use "../../vars/colorsCustomer" as *;

* {
  font-family: "Montserrat", "IBMPlexSansArabic" !important;
}
.landingConent {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .regFormLabel {
    color: $color_dark_red;
  }
  .regFormInput {
    border: 2px solid $color_gray_font;
    font-weight: 700;
    color: $color_gray_font;
    &:focus {
      box-shadow: none;
      border: 2px solid $color_gray_font;
    }
  }
  .phoneStyle {
    text-align: left;
  }
  .regFormPicc {
    width: 80%;
    max-width: 300px;
    height: auto;
  }
  .regFormPic {
    width: 100%;
    max-width: 350px;
    height: auto;
  }
  .cpcRegTitle {
    color: $color_main;
    font-weight: 800 !important;
    text-align: center;
  }
  .btnsGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    .choiceBtn {
      min-height: 100px !important;
      min-width: 100px !important;
      svg {
        font-size: 45px !important;
      }
    }
    .btnTagName {
      color: $color_main;
      font-size: 0.9rem;
      font-weight: 800;
    }
  }
}

@media (max-width: 512px) {
  * {
    font-size: 0.8rem;
  }
}
