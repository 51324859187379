.StationOwnerSoonContent {
  // background-color: ghostwhite;
  // background-image: url("../../../../imgs/coverrdd.jpeg");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center center;
  min-height: 100vh;
  position: relative;
  .ownerImg {
    min-height: 100vh;
    width: 100%;
    object-fit: cover;
  }
  .soonLogo {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 230px;
    object-fit: contain;
  }
}
