@use "../../../vars/colorsCustomer" as *;

.pageBtn {
  border: none;
  min-width: 30px;
  height: 30px;
  background-color: $color_secondary !important;
  color: $color_background !important;
  border-radius: 50%;
  font-weight: 500;
  transition: all 0.2s;
}

.activePageBtn {
  background-color: $color_background !important;
  color: whitesmoke !important;
}
