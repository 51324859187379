@use "../../../../vars/colors" as *;

[dir="rtl"] * {
  .cashoutContent {
    .redeemCashoutTitle {
      text-align: right;
      color: $color_main;
      font-size: 0.8rem;
      font-weight: 500;
    }
    .cashoutChoiceBtn {
      text-align: center;
      padding: 5px 35px;
      background-color: white !important;
      border: none !important;
      font-weight: 500;
      font-size: 0.8rem;
      white-space: wrap;
      color: $color_main !important;
      &:hover {
        background-color: transparent !important;
        color: $color_main !important;
        font-weight: 700;
        transition: all 0.1s;
      }
    }
    .cashoutChoiceBtnActive {
      text-align: center;
      font-weight: 700;
      border-bottom: 2px solid $color_main !important;
    }
  }
}
[dir="ltr"] * {
  .cashoutContent {
    .redeemCashoutTitle {
      text-align: left;
      color: $color_main;
      font-size: 0.8rem;
      font-weight: 500;
    }
    .cashoutChoiceBtn {
      text-align: center;
      padding: 5px 35px;
      background-color: white !important;
      border: none !important;
      font-weight: 500;
      font-size: 0.8rem;
      white-space: nowrap;
      color: $color_main !important;
      &:hover {
        background-color: transparent !important;
        color: $color_main !important;
        font-weight: 700;
        transition: all 0.1s;
      }
    }
    .cashoutChoiceBtnActive {
      font-weight: 700;
      border-bottom: 2px solid $color_main !important;
    }
  }
}
