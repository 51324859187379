@use "../../../../../../vars/_colors.scss" as *;

.freeUnit {
  .inputLabel {
    color: $color_main;
    font-weight: 700;
    font-size: 0.75rem;
  }
  .inputGroupForm {
    outline: none;
    border: 2px solid $color_main;
    background-color: white;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    input {
      border: none;
      outline: none;
      text-align: center;
      font-weight: 700;
      color: $color_main;
      &:focus {
        outline: none;
        box-shadow: none;
        text-align: center;
        font-weight: 700;
        color: $color_main;
      }
      &:disabled {
        background-color: transparent !important;
      }
    }
    span {
      outline: none;
      border: none;
      background-color: transparent !important;
      border-left: 3px solid $color_main;
      font-weight: 700;
      font-size: 0.7rem;
      color: $color_main;
    }
  }
  .unitOptionsHeader {
    button {
      background-color: $color_secondary;
      color: #9f9f9f;
      font-size: 0.9rem;
      font-weight: 600;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .unitOptionsBody {
    background-color: #dce2fe;
    color: $color_main;
    .inputLabelPrice,
    .inputLabelName {
      color: $color_main;
      font-weight: 600;
      font-size: 0.75rem;
    }
    .inputGroupFormPrice,
    .inputName {
      outline: none;
      background-color: white;
      border: 2px solid $color_main;
      border-radius: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      input {
        border: none;
        outline: none;
        text-align: center;
        font-weight: 700;
        color: $color_main;
        &:focus {
          outline: none;
          box-shadow: none;
          text-align: center;
          font-weight: 700;
          color: $color_main;
        }
      }
      span {
        outline: none;
        border: none;
        background-color: transparent !important;
        border-left: 3px solid $color_main;
        font-weight: 700;
        font-size: 0.7rem;
        color: $color_main;
      }
    }
  }
  .unitOptions {
    color: $color_main;
    font-weight: 700;
    font-size: 0.8rem;
  }
  .saveBtn {
    padding: 10px 40px;
    background-color: $color_main;
    font-weight: 400;
    font-size: 1rem;
    &:hover {
      background-color: $color_background;
    }
  }
}
