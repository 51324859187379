@use "../../vars/colors" as *;

* {
  font-family: "Montserrat", "IBMPlexSansArabic" !important;
}
[dir="rtl"] .RegFormContent {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .langBtn {
    position: absolute;
    top: 2%;
    left: 2%;
  }
  .regFormLabel {
    color: $color_main;
  }
  .regFormInput {
    border: 2px solid $color_gray_font;
    font-weight: 700;
    color: $color_gray_font;
    &:focus {
      box-shadow: none;
      border: 2px solid $color_gray_font;
    }
  }
  .phoneStyle {
    text-align: right;
  }
  .regFormPicc {
    width: 80%;
    max-width: 300px;
    height: auto;
  }
  .regFormPic {
    width: 100%;
    max-width: 350px;
    height: auto;
  }
  .cpcRegTitle {
    color: $color_main;
    font-weight: 800 !important;
    text-align: center;
  }
  .cpcRegSubTitle {
    color: $color_main;
    font-weight: 800 !important;
    text-align: right;
    font-size: 1rem;
  }
  .registerBtn {
    background-color: $color_main;
    color: white;
    &:hover {
      background-color: $color_background;
      color: white;
    }
  }
}

[dir="ltr"] .RegFormContent {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .langBtn {
    position: absolute;
    top: 2%;
    left: 2%;
  }
  .regFormLabel {
    color: $color_main;
  }
  .regFormInput {
    border: 2px solid $color_gray_font;
    font-weight: 700;
    color: $color_gray_font;
    &:focus {
      box-shadow: none;
      border: 2px solid $color_gray_font;
    }
  }
  .phoneStyle {
    text-align: left;
  }
  .regFormPicc {
    width: 80%;
    max-width: 300px;
    height: auto;
  }
  .regFormPic {
    width: 100%;
    max-width: 350px;
    height: auto;
  }
  .cpcRegTitle {
    color: $color_main;
    font-weight: 800 !important;
    text-align: center;
  }
  .cpcRegSubTitle {
    color: $color_main;
    font-weight: 800 !important;
    text-align: left;
  }
  .registerBtn {
    background-color: $color_main;
    color: white;
    &:hover {
      background-color: $color_background;
      color: white;
    }
  }
}

@media (max-width: 512px) {
  * {
    font-size: 0.8rem;
  }
}
