@use "../../../../vars/colors" as *;

[dir="rtl"] * {
  .giftCardContent {
    .redeemGiftTitle {
      text-align: right;
      color: $color_main;
      font-size: 0.8rem;
      font-weight: 500;
    }
    .cashoutInputContent {
      background-color: #dce2fe;
      border-radius: 10px;
      .termsBtn {
        text-decoration: underline !important;
        text-decoration-color: #3855f1 !important;
        -moz-text-decoration-color: #3855f1 !important;
        small {
          &:hover {
            font-weight: 600 !important;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
            -moz-transition: all 0.2s;
            -ms-transition: all 0.2s;
            -o-transition: all 0.2s;
          }
        }
      }
      .catsContent {
        max-height: 60px;
        width: 300px;
        overflow-x: auto;
        border: 1px solid #b5c1ff;
        border-radius: 10px;
        display: flex;
        justify-content: start;
        align-items: center;
        .catCheck {
          &:checked + label {
            background-color: $color_background;
            color: white;
          }
        }
        /* width */
        &::-webkit-scrollbar {
          width: 10px;
          height: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #dce2fe;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $color_main;
          border-radius: 15px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $color_background;
        }
        .catTypeBtn {
          text-align: center;
          white-space: nowrap;
          padding: 5px 20px;
          border-radius: 20px;
          background-color: #b5c1ff;
          color: $color_main;
          font-size: 0.7rem;
          font-weight: 600;
          &:hover {
            background-color: $color_main;
            color: white;
            cursor: pointer;
          }
        }
      }
      label {
        font-weight: 700;
        color: $color_main;
        font-size: 0.9rem;
      }
      .giftCardImg {
        background-color: whitesmoke;
        padding: 10px;
        border-radius: 10px;
        width: 100%;
        max-width: 70px;
        height: auto;
      }
      .giftCardInfo {
        h5 {
          font-weight: 800;
          font-size: 0.9rem;
          color: $color_main;
        }
        span {
          font-weight: 600;
          font-size: 0.75rem;
          color: $color_main;
        }
      }
      .giftCardInput {
        border: 1px solid #3855f1;
        font-weight: 600;
        text-align: center;
        color: $color_main;
        &:focus {
          box-shadow: none !important;
          border: 2px solid #3855f1 !important;
        }
      }
      .vouchValuInput {
        &:checked + label {
          background-color: $color_background;
          color: white;
        }
      }
      .voucherValueBtn {
        min-width: 75px !important;
        padding: 3px 25px;
        background-color: #b5c1ff;
        color: #3554f1;
        border-radius: 15px;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
      }
      small {
        color: $color_main;
        font-size: 0.7rem;
        font-weight: 500;
      }
      .cashoutInput {
        border: 1px solid #3855f1;
        font-weight: 600;
        text-align: center;
        color: $color_main;
        &:focus {
          box-shadow: none !important;
          border: 2px solid #3855f1 !important;
        }
      }
      .saveBtn {
        background-color: $color_main;
        font-weight: 700;

        &:hover {
          background-color: $color_background;
        }
      }
      .vouchersContent {
        max-height: 50vh;
        overflow-y: auto;
        .voucherBtn {
          background-color: #f5f5f5;
          padding: 10px;
          border-radius: 15px;
          // border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background-color: #b5c1ff;
            transition: all 0.2s;
            cursor: pointer;
          }
          img {
            width: 45px;
            height: 45px;
            object-fit: contain;
          }
        }
        &::-webkit-scrollbar {
          width: 7px;
          height: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #dce2fe;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $color_main;
          border-radius: 15px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $color_background;
        }
      }
    }
    .sendGiftBtn {
      background-color: $color_main;
      font-weight: 500;
      font-size: 0.9rem;
      &:hover {
        background-color: $color_background;
      }
    }
    .closeBtn {
      background-color: $color_secondary;
      color: $color_light;
      font-size: 0.9rem;
      font-weight: 500;
      &:hover {
        background-color: $color_light;
        color: whitesmoke;
      }
    }
  }
}

[dir="ltr"] * {
  .giftCardContent {
    .redeemGiftTitle {
      text-align: left;
      color: $color_main;
      font-size: 0.8rem;
      font-weight: 500;
    }
    .cashoutInputContent {
      background-color: #dce2fe;
      border-radius: 10px;
      .termsBtn {
        text-decoration: underline !important;
        text-decoration-color: #3855f1 !important;
        -moz-text-decoration-color: #3855f1 !important;
        small {
          &:hover {
            font-weight: 600 !important;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
            -moz-transition: all 0.2s;
            -ms-transition: all 0.2s;
            -o-transition: all 0.2s;
          }
        }
      }
      .catsContent {
        max-height: 60px;
        width: 300px;
        overflow-x: auto;
        border: 1px solid #b5c1ff;
        border-radius: 10px;
        display: flex;
        justify-content: start;
        align-items: center;
        .catCheck {
          &:checked + label {
            background-color: $color_background;
            color: white;
          }
        }
        /* width */
        &::-webkit-scrollbar {
          width: 10px;
          height: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #dce2fe;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $color_main;
          border-radius: 15px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $color_background;
        }
        .catTypeBtn {
          text-align: center;
          white-space: nowrap;
          padding: 5px 20px;
          border-radius: 20px;
          background-color: #b5c1ff;
          color: $color_main;
          font-size: 0.7rem;
          font-weight: 600;
          &:hover {
            background-color: $color_main;
            color: white;
            cursor: pointer;
          }
        }
      }
      label {
        font-weight: 700;
        color: $color_main;
        font-size: 0.9rem;
      }
      .giftCardImg {
        background-color: whitesmoke;
        padding: 10px;
        border-radius: 10px;
        width: 100%;
        max-width: 70px;
        height: auto;
      }
      .giftCardInfo {
        h5 {
          font-weight: 800;
          font-size: 0.9rem;
          color: $color_main;
        }
        span {
          font-weight: 600;
          font-size: 0.75rem;
          color: $color_main;
        }
      }
      .giftCardInput {
        border: 1px solid #3855f1;
        font-weight: 600;
        text-align: center;
        color: $color_main;
        &:focus {
          box-shadow: none !important;
          border: 2px solid #3855f1 !important;
        }
      }
      .vouchValuInput {
        &:checked + label {
          background-color: $color_background;
          color: white;
        }
      }
      .voucherValueBtn {
        min-width: 75px !important;
        padding: 3px 25px;
        background-color: #b5c1ff;
        color: #3554f1;
        border-radius: 15px;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
      }
      small {
        color: $color_main;
        font-size: 0.7rem;
        font-weight: 500;
      }
      .cashoutInput {
        border: 1px solid #3855f1;
        font-weight: 600;
        text-align: center;
        color: $color_main;
        &:focus {
          box-shadow: none !important;
          border: 2px solid #3855f1 !important;
        }
      }
      .saveBtn {
        background-color: $color_main;
        font-weight: 700;

        &:hover {
          background-color: $color_background;
        }
      }
      .vouchersContent {
        max-height: 50vh;
        overflow-y: auto;
        .voucherBtn {
          background-color: #f5f5f5;
          padding: 10px;
          border-radius: 15px;
          // border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background-color: #b5c1ff;
            transition: all 0.2s;
            cursor: pointer;
          }
          img {
            width: 45px;
            height: 45px;
            object-fit: contain;
          }
        }
        &::-webkit-scrollbar {
          width: 7px;
          height: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #dce2fe;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $color_main;
          border-radius: 15px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $color_background;
        }
      }
    }
    .sendGiftBtn {
      background-color: $color_main;
      font-weight: 500;
      font-size: 0.9rem;
      &:hover {
        background-color: $color_background;
      }
    }
    .closeBtn {
      background-color: $color_secondary;
      color: $color_light;
      font-size: 0.9rem;
      font-weight: 500;
      &:hover {
        background-color: $color_light;
        color: whitesmoke;
      }
    }
  }
}
