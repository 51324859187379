@use "../../vars/colors" as *;

.pageBtn {
  border: none;
  // min-width: 30px;
  // height: 30px;
  background-color: $color_secondary !important;
  color: $color_background !important;
  border-radius: 50%;
  font-weight: 500;
  transition: all 0.2s;
}
.paginationContent {
  .nextBtn,
  .backBtn {
    background-color: $color_background;
    font-size: 0.8rem;
  }
}

.activePageBtn {
  background-color: $color_background !important;
  color: whitesmoke !important;
}

.pagesCount {
  background-color: $color_main;
  color: white;
  max-height: 200px;
  overflow: overlay;
  ul {
    min-width: 50px;
    padding: 10px;
    margin: 0px;
    list-style-type: none !important;
    li {
      text-align: center;
      display: block;
      width: 100% !important;
      &:hover {
        background-color: $color_background;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
      }
    }
  }
}

.pageCountPara {
  font-size: 0.7rem!important;
  font-weight: 700!important;
  color: $color_dark_red!important;
}

.pageInput {
  outline: none;
  border: none;
  border: 1.5px solid $color_background;
  background-color: ghostwhite;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  color: $color_background;
  &:focus{
    box-shadow: none!important;
  }
}
