@use "../../../vars/colors" as *;

[dir="rtl"] * {
  .ownerLandingPageContent {
    .listItem {
      background-color: transparent !important;
      font-size: 0.8rem;
      font-weight: 500;
      color: whitesmoke;
      text-align: left;
      display: flex;
      align-items: start;
      .listIcon {
        font-size: 0.8rem !important;
      }
      &:hover {
        background-color: #0f24d4 !important;
        transition: all 0.3s;
      }
    }
    .adminContent {
      background-color: white;
      border-radius: 20px;
      margin: 10px;
    }
  }

  .menuIcon {
    font-size: 1.4em;
    color: #0f24d4;
  }
}
[dir="ltr"] * {
  .ownerLandingPageContent {
    .listItem {
      background-color: transparent !important;
      font-size: 0.8rem;
      font-weight: 500;
      color: whitesmoke;
      text-align: left;
      display: flex;
      align-items: start;
      .listIcon {
        font-size: 0.8rem !important;
      }
      &:hover {
        background-color: #0f24d4 !important;
        transition: all 0.3s;
      }
    }
    .adminContent {
      background-color: white;
      border-radius: 20px;
      margin: 10px;
    }
  }

  .menuIcon {
    font-size: 1.4em;
    color: #0f24d4;
  }
}
