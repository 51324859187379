@use "../../../vars/colors" as *;

[dir="rtl"] * {
  .NationalIDContent {
    .DashboardTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
    }
    .NationalIDContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .progress {
        height: 10px;
        width: 100%;
        background-color: #f8f8ff;
        border-radius: 30px !important;
        .progressBar {
          height: 100%;
          width: 0%;
          border-radius: inherit;
          background-color: $color_main;
          transition: all 0.2s;
        }
      }
      h6 {
        color: $color_main;
        font-weight: 500;
        font-size: 0.8rem;
      }
      .NationalIDPic {
        width: 100%;
        max-width: 300px;
        height: auto;
        padding: 15px;
        border: 1px dashed $color_main;
        border-radius: 10px;
      }
    }
    .uploadBtn {
      background-color: #ffffff !important;
      border: 2px solid $color_main;
      border-radius: 10px;
      color: $color_main;
      font-size: 0.9rem;
      max-width: 200px;
      &:hover {
        background-color: $color_main !important;
        color: #ffffff !important;
      }
    }
    .nationalResult {
      background-color: $color_secondary;
      border: 2px solid $color_main;
      border-radius: 10px;
      color: $color_main;
      font-size: 0.85rem;
      font-weight: 600;
      text-align: center;
      max-width: 200px;
      &:focus {
        box-shadow: none !important;
        text-align: center;
        font-weight: 600;
      }
      &::placeholder {
        text-align: center;
        font-weight: 600;
        color: $color_light;
      }
    }
    .confirmBtn {
      padding: 10px 60px;
      background-color: $color_main;
      font-weight: 500;
      font-size: 0.9rem;
      &:hover {
        background-color: $color_background;
      }
      &:disabled {
        background-color: $color_light;
        border: none;
      }
    }
  }

  @media (max-width: 478px) {
    .nationalResult {
      margin-top: 10px;
    }
  }
}
[dir="ltr"] * {
  .NationalIDContent {
    .DashboardTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
    }
    .NationalIDContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .progress {
        height: 10px;
        width: 100%;
        background-color: #f8f8ff;
        border-radius: 30px !important;
        .progressBar {
          height: 100%;
          width: 0%;
          border-radius: inherit;
          background-color: $color_main;
          transition: all 0.2s;
        }
      }
      h6 {
        color: $color_main;
        font-weight: 500;
        font-size: 0.8rem;
      }
      .NationalIDPic {
        width: 100%;
        max-width: 300px;
        height: auto;
        padding: 15px;
        border: 1px dashed $color_main;
        border-radius: 10px;
      }
    }
    .uploadBtn {
      background-color: #ffffff !important;
      border: 2px solid $color_main;
      border-radius: 10px;
      color: $color_main;
      font-size: 0.9rem;
      max-width: 200px;
      &:hover {
        background-color: $color_main !important;
        color: #ffffff !important;
      }
    }
    .nationalResult {
      background-color: $color_secondary;
      border: 2px solid $color_main;
      border-radius: 10px;
      color: $color_main;
      font-size: 0.85rem;
      font-weight: 600;
      text-align: center;
      max-width: 200px;
      &:focus {
        box-shadow: none !important;
        text-align: center;
        font-weight: 600;
      }
      &::placeholder {
        text-align: center;
        font-weight: 600;
        color: $color_light;
      }
    }
    .confirmBtn {
      padding: 10px 60px;
      background-color: $color_main;
      font-weight: 500;
      font-size: 0.9rem;
      &:hover {
        background-color: $color_background;
      }
      &:disabled {
        background-color: $color_light;
        border: none;
      }
    }
  }

  @media (max-width: 478px) {
    .nationalResult {
      margin-top: 10px;
    }
  }
}
