body {
  margin: 0px;
  font-family: "Montserrat" !important;
}

/* .btn-primary.active {
  padding: 5px 25px;
  background-color: #3453f1 !important;
  font-weight: 400 !important;
  font-size: 0.8rem !important;
  border-radius: 15px !important;
  color: #ffff !important;
} */

.text-error {
  color: #ec1c24 !important;
  font-weight: 500 !important;
  font-size: 0.8rem !important;
}
.text-successs {
  color: #07bc07 !important;
  font-weight: 500 !important;
  font-size: 0.8rem !important;
}
.searchWrapper {
  border: none !important;
  border-radius: 10px !important;
}

.searchBox::placeholder {
  color: white;
}

.chip {
  display: none !important;
}

.optionListContainer {
  background: #3554f1 !important;
  bottom: 45px;
}

.optionListContainer ul {
  max-height: 300px !important;
}
.highlightOption {
  background: #0f24d4 !important;
}

.multiSelectContainer li:hover {
  background: #0f24d4 !important;
}

.react-html5-camera-photo {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-SemiBold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-Regular.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-Medium.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-Bold.ttf");
  font-weight: 800;
}

* {
  font-family: "Montserrat", "IBMPlexSansArabic" !important;
}

.react-datepicker-popper {
  z-index: 999 !important;
}

.ag-cell {
  text-align: center;
}

.ag-header-cell-label {
  justify-content: center!important;
}
/* .ag-cell-label-container {
  justify-content: center !important;
} */

.ag-theme-alpine .ag-icon-menu {
  background: transparent url("./imgs/icons8-filter-50.png") center/contain
    no-repeat;
  color: transparent;
  font-size: 25px !important;
}
.ag-theme-alpine .ag-icon-aggregation {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/aggregation.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-arrows {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/arrows.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-asc {
  background: transparent url("./imgs/icons8-sort-alpha-up-32.png")
    center/contain no-repeat;
  color: transparent;
  font-size: 35px !important;
}

.ag-theme-alpine .ag-icon-cancel {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/cancel.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-chart {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/chart.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-checkbox-checked {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/checkbox-checked.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-checkbox-indeterminate {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/checkbox-indeterminate.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-checkbox-unchecked {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/checkbox-unchecked.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-color-picker {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/color-picker.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-column {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/column.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-columns {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/columns.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-contracted {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/contracted.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-copy {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/copy.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-cut {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/cut.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-cross {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/cross.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-cut {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/cut.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-data {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/data.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-desc {
  background: transparent url("./imgs/icons8-sort-alpha-up-32.png")
    center/contain no-repeat;
  color: transparent;
  font-size: 35px !important;
}

.ag-theme-alpine .ag-icon-expanded {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/expanded.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-eye-slash {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/eye-slash.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-eye {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/eye.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-filter {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/filter.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-first {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/first.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-grip {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/grip.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-group {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/group.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-indeterminate {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/indeterminate.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-last {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/last.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-left {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/left.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-loading {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/loading.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-maximize {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/maximize.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-minimize {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/minimize.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-minus {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/minus.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-next {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/next.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-none {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/none.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-not-allowed {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/not-allowed.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-paste {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/paste.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-pin {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/pin.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-pivot {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/pivot.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-plus {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/plus.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-previous {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/previous.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-radio-button-off {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/radio-button-off.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-radio-button-on {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/radio-button-on.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-right {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/right.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-save {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/save.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-small-down {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/small-down.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-small-left {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/small-left.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-small-right {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/small-right.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

ag-theme-alpine .ag-icon-small-up {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/small-up.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-tick {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/tick.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-tree-closed {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/tree-closed.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-tree-indeterminate {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/tree-indeterminate.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}

.ag-theme-alpine .ag-icon-tree-open {
  background: transparent
    url("https://www.ag-grid.com/example-assets/svg-icons/tree-open.svg")
    center/contain no-repeat;
  color: transparent;
  font-size: 45px !important;
}
