@use "../../../../vars/_colors.scss" as *;

.giftPointContent{

}
.giftPointBody{
    h4{
        font-size: 0.8rem;
        font-weight: 800;
        color: $color-main;
        text-align: center;
    }
    p{
        font-size: 0.75rem;
        font-weight: 700;
        color: #B5B5B5;
        text-align: center;
    }
    .okBtn{
        background-color: $color-main;
        font-size: 0.7rem;
        font-weight: 600;
        color: white;
        &:hover{
            background-color: $color-background;
        }
    }
}