@use "../../../vars/colors" as *;

[dir="ltr"] .adminTeam {
  .teamTitle {
    font-weight: 700;
    font-size: 1.2rem;
    color: $color_main;
    text-align: left;
  }
  .btnRight {
    text-align: right;
    .reportBtn {
      padding: 15px 20px;
      background-color: $color_gray_font;
      font-weight: 400;
      font-size: 0.9rem;
      &:hover {
        background-color: $color_grey_font;
      }
    }
    button {
      padding: 15px 20px;
      background-color: $color_main;
      font-weight: 400;
      font-size: 0.9rem;
      &:hover {
        background-color: $color_background;
      }
    }
  }
  .filterForm {
    width: 290px;
    max-width: 300px;
    .filterInput {
      background-color: $color_main;
      padding: 10px 10px 10px 0px;
      border-radius: 7px;
      .filterLabel {
        background-color: transparent !important;
        border: none !important;
        svg {
          color: white !important;
          font-size: 1rem !important;
        }
      }
      .filterSelect {
        border-radius: 5px !important;
        color: $color_main;
        font-weight: 600;
        font-size: 0.8rem;
      }
    }
  }
  .searchForm {
    width: 350px;
    max-width: 370px;
    .searchInput {
      background-color: transparent !important;
      padding: 3px 3px 3px 0px;
      border: 1.5px solid #3454f1;
      border-radius: 10px;
      .searchLabel {
        background-color: transparent !important;
        border: none !important;
        svg {
          color: $color_main !important;
          font-size: 1rem !important;
        }
      }
      .searchField {
        outline: none;
        border: none;
        border-radius: 5px !important;
        color: $color_main !important;
        font-weight: 500 !important;
        font-size: 0.8rem;
        &:focus {
          outline: none;
          box-shadow: none;
          font-weight: 600;
        }
        &::placeholder {
          color: $color_main;
          font-weight: 500 !important;
        }
      }
    }
  }
  .bgGrey {
    background-color: $color_secondary;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 127px;
    .activatorInfoContent {
      height: 100%;
      flex-flow: row;
      flex-wrap: wrap;
      img {
        width: 80px;
        height: auto;
        border: 2px solid $color_gray_font;
        border-radius: 50%;
      }
      .activatorInfo {
        h5 {
          color: $color_grey_font;
          font-size: 1.1rem;
          font-weight: 800;
        }
        p {
          color: $color_grey_font;
          font-size: 0.9rem;
          font-weight: 500;
        }
        span {
          color: $color_grey_font;
          font-size: 0.7rem;
          font-size: 600;
        }
      }
      .activatedBadge {
        font-size: 0.7rem;
        color: $color_main;
        font-weight: 700;
        span {
          color: white;
        }
      }
      .assignedBadge {
        font-size: 0.7rem;
        color: $color_main;
        font-weight: 500;
        span {
          background-color: white;
          color: $color_main;
        }
      }
    }
    .activatorBtnContent {
      .editBtn {
        background-color: $color_main;
        font-size: 0.8rem;
        font-weight: 500;
      }
      .reportBtn {
        background-color: $color_gray_font;
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
  }
}
[dir="rtl"] .adminTeam {
  .teamTitle {
    font-weight: 700;
    font-size: 1.2rem;
    color: $color_main;
    text-align: right;
  }
  .btnRight {
    text-align: left;
    .reportBtn {
      padding: 15px 20px;
      background-color: $color_gray_font;
      font-weight: 400;
      font-size: 0.9rem;
      &:hover {
        background-color: $color_grey_font;
      }
    }
    button {
      padding: 15px 20px;
      background-color: $color_main;
      font-weight: 400;
      font-size: 0.9rem;
      &:hover {
        background-color: $color_background;
      }
    }
  }
  .filterForm {
    width: 290px;
    max-width: 300px;
    .filterInput {
      background-color: $color_main;
      padding: 10px 10px 10px 10px;
      border-radius: 7px;
      .filterLabel {
        background-color: transparent !important;
        border: none !important;
        svg {
          color: white !important;
          font-size: 1rem !important;
        }
      }
      .filterSelect {
        border-radius: 5px !important;
        color: $color_main;
        font-weight: 600;
        font-size: 0.8rem;
      }
    }
  }
  .searchForm {
    width: 350px;
    max-width: 370px;
    .searchInput {
      background-color: transparent !important;
      padding: 3px 3px 3px 0px;
      border: 1.5px solid #3454f1;
      border-radius: 10px;
      .searchLabel {
        background-color: transparent !important;
        border: none !important;
        svg {
          color: $color_main !important;
          font-size: 1rem !important;
        }
      }
      .searchField {
        outline: none;
        border: none;
        border-radius: 5px !important;
        color: $color_main !important;
        font-weight: 500 !important;
        font-size: 0.8rem;
        &:focus {
          outline: none;
          box-shadow: none;
          font-weight: 600;
        }
        &::placeholder {
          color: $color_main;
          font-weight: 500 !important;
        }
      }
    }
  }
  .bgGrey {
    background-color: $color_secondary;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 127px;
    .activatorInfoContent {
      height: 100%;
      flex-flow: row;
      flex-wrap: wrap;
      img {
        width: 80px;
        height: auto;
        border: 2px solid $color_gray_font;
        border-radius: 50%;
      }
      .activatorInfo {
        h5 {
          color: $color_grey_font;
          font-size: 1.1rem;
          font-weight: 800;
        }
        p {
          color: $color_grey_font;
          font-size: 0.9rem;
          font-weight: 500;
        }
        span {
          color: $color_grey_font;
          font-size: 0.7rem;
          font-size: 600;
        }
      }
      .activatedBadge {
        font-size: 0.7rem;
        color: $color_main;
        font-weight: 700;
        span {
          color: white;
        }
      }
      .assignedBadge {
        font-size: 0.7rem;
        color: $color_main;
        font-weight: 500;
        span {
          background-color: white;
          color: $color_main;
        }
      }
    }
    .activatorBtnContent {
      .editBtn {
        background-color: $color_main;
        font-size: 0.8rem;
        font-weight: 500;
      }
      .reportBtn {
        background-color: $color_gray_font;
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
  }
}
