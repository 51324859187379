@use "../../../vars/colorsCustomer" as *;

[dir="rtl"] * {
  .changePasswordContent {
    text-align: right;
    .teamTitle {
      .backBtn {
        rotate: 180deg;
        cursor: pointer;
      }
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
    }
    .btnRight {
      text-align: left;
    }
    .changePasswordFormContent {
      border: 2px solid $color_main;
      border-radius: 15px;
      .addInputLabel {
        font-weight: 600;
        color: $color_main;
      }
      .addInput {
        min-height: 50px;
        border: 1px solid $color_main;
        font-weight: 400;
        color: $color_background;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .addSelect {
        min-height: 50px;
        border: 1px solid $color_main;
        font-weight: 400;
        color: white;
        background-color: $color_main;
        // background-image: url("../../../../imgs/arrow-204-16.png");
        background-size: 12px;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .addMultiSelect {
        min-height: 50px;
        border: 1px solid $color_main;
        padding-top: 3px;
        border-radius: 5px;
        line-height: 1.5;
        font-weight: 400;
        color: white;
        background-color: $color_main;
        // background-image: url("../../../imgs/arrow-204-16.png");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 12px;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  .chipCustom {
    align-items: center;
    background: white;
    border: 1px solid $color_main;
    border-radius: 11px;
    color: $color_main;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 4px 10px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .saveBtn {
    padding: 10px 40px;
    background-color: $color_main;
    font-weight: 400;
    font-size: 1rem;
    &:hover {
      background-color: $color_background;
    }
  }
}

[dir="ltr"] * {
  .changePasswordContent {
    text-align: left;
    .teamTitle {
      .backBtn {
        cursor: pointer;
      }
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
    }
    .btnRight {
      text-align: right;
    }
    .changePasswordFormContent {
      border: 2px solid $color_main;
      border-radius: 15px;
      .addInputLabel {
        font-weight: 600;
        color: $color_main;
      }
      .addInput {
        min-height: 50px;
        border: 1px solid $color_main;
        font-weight: 400;
        color: $color_background;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .addSelect {
        min-height: 50px;
        border: 1px solid $color_main;
        font-weight: 400;
        color: white;
        background-color: $color_main;
        // background-image: url("../../../../imgs/arrow-204-16.png");
        background-size: 12px;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .addMultiSelect {
        min-height: 50px;
        border: 1px solid $color_main;
        padding-top: 3px;
        border-radius: 5px;
        line-height: 1.5;
        font-weight: 400;
        color: white;
        background-color: $color_main;
        // background-image: url("../../../imgs/arrow-204-16.png");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 12px;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  .chipCustom {
    align-items: center;
    background: white;
    border: 1px solid $color_main;
    border-radius: 11px;
    color: $color_main;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 4px 10px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .saveBtn {
    padding: 10px 40px;
    background-color: $color_main;
    font-weight: 400;
    font-size: 1rem;
    &:hover {
      background-color: $color_background;
    }
  }
}
