@use "../../../../../vars/colors" as *;

[dir="rtl"] * {
  .StationOrdersReportContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
      .backBtn {
        rotate: 180deg;
        cursor: pointer;
      }
    }
    .filterContent {
      background-color: #dce2fe;
      border: 1px solid $color_main;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;

      .filterInputContent {
        label {
          font-weight: 700;
          color: $color_main;
          font-size: 0.9rem;
        }
        small {
          color: $color_main;
          font-size: 0.7rem;
          font-weight: 500;
        }
        .filterSelect {
          font-weight: 600;
          font-size: 0.8rem;
          background-color: $color_main;
          color: white;
          &:focus {
            box-shadow: none;
            border: none;
          }
        }
        .filterInput {
          border: 1px solid $color_background;
          font-weight: 600;
          color: $color_main;
          &:focus {
            box-shadow: none !important;
            border: 2px solid $color_background !important;
          }
        }
        .filterLabel {
          font-weight: 600;
          font-size: 0.7rem;
          color: $color_main;
        }
      }
    }
    .customerBtn {
      background-color: $color_main;
      font-size: 13px;
    }
    .stationBtn {
      background-color: #dce2fe;
      font-size: 13px;
      color: $color_background;
    }
  }
}
[dir="ltr"] * {
  .StationOrdersReportContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
      .backBtn {
        cursor: pointer;
      }
    }
    .filterContent {
      background-color: #dce2fe;
      border: 1px solid $color_main;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      .alertMessage {
        background-color: #fcc1c1;
        font-weight: 600;
        font-size: 0.75rem;
        color: #cb0707;
        border: 1.5px solid #cb0707;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
      }
      .filterInputContent {
        label {
          font-weight: 700;
          color: $color_main;
          font-size: 0.9rem;
        }
        small {
          color: $color_main;
          font-size: 0.7rem;
          font-weight: 500;
        }
        .filterSelect {
          font-weight: 600;
          font-size: 0.8rem;
          background-color: $color_main;
          color: white;
          &:focus {
            box-shadow: none;
            border: none;
          }
        }
        .filterInput {
          border: 1px solid $color_background;
          font-weight: 600;
          font-size: 0.75rem;
          color: $color_main;
          &:focus {
            box-shadow: none !important;
            border: 2px solid $color_background !important;
          }
        }
        .filterLabel {
          font-weight: 600;
          font-size: 0.7rem;
          color: $color_main;
        }
        .dateInput {
          border: 1px solid $color_background;
          font-weight: 600;
          font-size: 0.8rem;
          text-align: center;
          color: $color_main;
          min-height: 30px;
          &:focus {
            box-shadow: none !important;
            border: 2px solid $color_background !important;
          }
        }
      }
    }
    .customerBtn {
      background-color: $color_main;
      font-size: 13px;
    }
    .stationBtn {
      background-color: #dce2fe;
      font-size: 13px;
      color: $color_background;
    }
  }
}

#popover-basic {
  .popoveStyle {
    h6 {
      font-size: 0.7rem !important;
    }
  }
}
