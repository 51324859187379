@use "../../../../vars/colors" as *;

[dir="rtl"] * {
  .areaManagerStationsDetailsContent {
    text-align: right;
    .teamTitle {
      .backBtn {
        rotate: 180deg;
        cursor: pointer;
      }
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
    }
  }
  .detailsElement {
    background-color: white;
    box-shadow: 0px 0px 10px rgba(3, 3, 3, 0.1);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    .detailsTitle {
      font-weight: 900;
      font-size: 1rem;
      color: #181d1f;
    }
    .detailsSubTitle {
      font-weight: 600;
      font-size: 0.9rem;
      color: #181d1f;
    }
  }
}

[dir="ltr"] * {
  .areaManagerStationsDetailsContent {
    text-align: left;
    .teamTitle {
      .backBtn {
        cursor: pointer;
      }
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
    }
    .detailsElement {
      background-color: white;
      box-shadow: 0px 0px 10px rgba(3, 3, 3, 0.1);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      .detailsTitle {
        font-weight: 900;
        font-size: 1rem;
        color: #181d1f;
      }
      .detailsSubTitle {
        font-weight: 600;
        font-size: 0.9rem;
        color: #181d1f;
      }
    }
  }
}
