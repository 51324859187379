@use "../../../../../vars/colors" as *;

.refCodeContent {
  position: relative;
  .cashoutInputContent {
    background-color: #dce2fe;
    border-radius: 10px;
    label {
      font-weight: 700;
      color: $color_main;
      font-size: 0.9rem;
    }
    small {
      color: $color_main;
      font-size: 0.7rem;
      font-weight: 500;
    }
    .cashoutInput {
      border: 1px solid #3855f1;
      font-weight: 600;
      text-align: center;
      color: $color_main;
      &:focus {
        box-shadow: none !important;
        border: 2px solid #3855f1 !important;
      }
    }
    .saveBtn {
      background-color: $color_main;
      font-weight: 700;

      &:hover {
        background-color: $color_background;
      }
    }
  }
  .comingSoon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #dce2fee3;
    border-radius: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    h5 {
      color: $color_main;
      font-weight: 900;
      font-style: italic;
      font-size: 2rem;
    }
  }
}
