@use "../../.././../../vars/colors" as *;

[dir="rtl"] * {
  .assignedContent {
    .bgGrey {
      background-color: $color_secondary;
      border-radius: 15px;
      h4 {
        color: $color_grey_font;
        font-weight: 700;
        font-size: 1.1rem;
      }

      .branchContent {
        background-color: $color_main !important;
        color: white !important;
        border: 1px solid $color_main;
        font-size: 0.75rem;
        font-weight: 700;
        cursor: pointer;
      }
      .infoContent {
        h5 {
          color: $color_grey_font;
          font-weight: 700;
          font-size: 1.1rem;
        }
        p,
        span {
          color: $color_grey_font;
          font-weight: 500;
          font-size: 0.8rem;
        }
      }
      .btnRegisterGroup {
        .registerBtn {
          padding: 5px 20px;
          background-color: $color_main;
          font-weight: 600;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_background;
          }
        }
      }
      .branchContentParent {
        background-color: $color_main !important;
        border-radius: 15px;
        .branchContentBody {
          color: white;
          font-size: 0.75rem;
          font-weight: 700 !important;
          ul {
            list-style-type: none !important;
          }
        }
      }
    }
  }
}
[dir="ltr"] * {
  .assignedContent {
    .bgGrey {
      background-color: $color_secondary;
      border-radius: 15px;
      h4 {
        color: $color_grey_font;
        font-weight: 700;
        font-size: 1.1rem;
      }
      .branchContent {
        background-color: $color_main !important;
        color: white !important;
        border: 1px solid $color_main;
        font-size: 0.75rem;
        font-weight: 700;
        cursor: pointer;
      }
      .infoContent {
        h5 {
          color: $color_grey_font;
          font-weight: 700;
          font-size: 1.1rem;
        }
        p,
        span {
          color: $color_grey_font;
          font-weight: 500;
          font-size: 0.8rem;
        }
      }
      .btnRegisterGroup {
        .registerBtn {
          padding: 5px 20px;
          background-color: $color_main;
          font-weight: 600;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_background;
          }
        }
      }
      .branchContentParent {
        background-color: $color_main !important;
        border-radius: 15px;
        .branchContentBody {
          color: white;
          font-size: 0.75rem;
          font-weight: 700 !important;
          ul {
            list-style-type: none !important;
          }
        }
      }
    }
  }
}

.branchContentParent {
  background-color: $color_main !important;
  border-radius: 15px;
  .branchContentBody {
    color: white;
    font-size: 0.75rem;
    font-weight: 700 !important;
    ul {
      list-style-type: none !important;
    }
  }
}
