@use "../../../../vars/colors" as *;

.giftPointBody {
  h4 {
    font-size: 1rem;
    font-weight: 800;
    color: black;
  }
  p {
    font-size: 0.75rem;
    font-weight: 700;
    color: black;
  }
  .okBtn {
    padding: 5px 20px;
    border: none;
    background-color: #f51616;
    font-size: 0.7rem;
    font-weight: 600;
    color: white;
    &:hover {
      background-color: #cf1010;
      color: white;
    }
  }
  .noBtn {
    padding: 5px 20px;
    border: none;
    background-color: #8397fc;
    font-size: 0.7rem;
    font-weight: 600;
    color: white;
    &:hover {
      background-color: #697ee7;
      color: white;
    }
  }
}
