@use "../../../../vars/colors" as *;

[dir="ltr"] .earnSchemeContent {
  .earnSchemePar {
    background-color: #dce2fe;
    color: $color_main;
    border-radius: 10px;
    font-weight: 600;
    font-size: 0.85rem;
  }
  .searchForm {
    max-width: 370px;
    .searchInput {
      background-color: transparent !important;
      padding: 3px 3px 3px 0px;
      border: 1.5px solid #3454f1;
      border-radius: 10px;
      .searchLabel {
        background-color: transparent !important;
        border: none !important;
        svg {
          color: $color_main !important;
          font-size: 1rem !important;
        }
      }
      .searchField {
        outline: none;
        border: none;
        border-radius: 5px !important;
        color: $color_main !important;
        font-weight: 500 !important;
        font-size: 0.8rem;
        &:focus {
          outline: none;
          box-shadow: none;
          font-weight: 600;
        }
        &::placeholder {
          color: $color_main;
          font-weight: 500 !important;
        }
      }
    }
  }
  .earnedSchemeForm {
    .accordContent {
      border-radius: 10px;
      .accordParent {
        background-color: #dce2fe;
        .accordTitle {
          button {
            background-color: #dce2fe;
            color: $color_main;
            font-size: 0.9rem;
            font-weight: 700;
            &:focus {
              box-shadow: none;
            }
            &::after {
              margin-right: 0px;
              background-image: url("../../../../imgs/arrow-204-16.png");
              background-repeat: no-repeat;
              background-size: 1rem;
            }
          }
          .newIcon {
            width: 15px;
            height: 10px;
            background-color: #198754;
            border-radius: 15px;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
}
          h6 {
            font-size: 0.7rem;
          }
        }
        .accordBody {
          .earnedSchemeInputGroupContent {
            .earnedSchemeInputGroup {
              &:focus {
                box-shadow: none;
                border: none;
              }
              &:disabled {
                background-color: #afbcf8;
                color: #6573b8;
                font-weight: 700;
                border-right: 1.5px solid #6573b8;
                text-align: center;
                font-size: 0.9rem;
              }
            }
            .earnedSchemeInputText {
              background-color: #afbcf8;
              color: #6573b8;
              font-weight: 700;
              font-size: 0.7rem;
            }
            .earnedSchemeInputTextt {
              background-color: #889cff;
              color: #f2f2f2;
              font-weight: 700;
              font-size: 0.7rem;
            }
          }
          .earnedSchemeLabel {
            color: #444c75;
            font-weight: 600;
            font-size: 0.85rem;
          }
          .earnedSchemeSmallLabel {
            color: #444c75;
            font-weight: 700;
            font-size: 0.8rem;
          }
          .earnedSchemeInput {
            background-color: #889cff;
            color: white;
            font-weight: 700;
            text-align: center;
            font-size: 0.9rem;
            &:focus {
              box-shadow: none !important;
            }
            &:disabled {
              background-color: #afbcf8;
              color: #6573b8;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .saveBtn {
    padding: 10px 40px;
    background-color: $color_main;
    color: #ffffff;
    &:hover {
      background-color: $color_background;
    }
  }
}

[dir="rtl"] .earnSchemeContent {
  .earnSchemePar {
    background-color: #dce2fe;
    color: $color_main;
    border-radius: 10px;
    font-weight: 600;
    font-size: 0.85rem;
  }
  .searchForm {
    max-width: 370px;
    .searchInput {
      background-color: transparent !important;
      padding: 3px 3px 3px 3px;
      border: 1.5px solid #3454f1;
      border-radius: 10px;
      .searchLabel {
        background-color: transparent !important;
        border: none !important;
        svg {
          color: $color_main !important;
          font-size: 1rem !important;
        }
      }
      .searchField {
        outline: none;
        border: none;
        border-radius: 5px !important;
        color: $color_main !important;
        font-weight: 500 !important;
        font-size: 0.8rem;
        &:focus {
          outline: none;
          box-shadow: none;
          font-weight: 600;
        }
        &::placeholder {
          color: $color_main;
          font-weight: 500 !important;
        }
      }
    }
  }
  .earnedSchemeForm {
    .accordContent {
      border-radius: 10px;
      .accordParent {
        background-color: #dce2fe;
        .accordTitle {
          button {
            background-color: #dce2fe;
            color: $color_main;
            font-size: 0.9rem;
            font-weight: 700;
            &:focus {
              box-shadow: none;
            }
            &::after {
              margin-left: 0px;
              background-image: url("../../../../imgs/arrow-204-16.png");
              background-repeat: no-repeat;
              background-size: 1rem;
            }
          }
        }
        .accordBody {
          .earnedSchemeInputGroupContent {
            .earnedSchemeInputGroup {
              &:focus {
                box-shadow: none;
                border: none;
              }
              &:disabled {
                background-color: #afbcf8;
                color: #6573b8;
                font-weight: 700;
                border-left: 1.5px solid #6573b8;
                text-align: center;
                font-size: 0.9rem;
              }
            }
            .earnedSchemeInputText {
              background-color: #afbcf8;
              color: #6573b8;
              font-weight: 700;
              font-size: 0.7rem;
            }
            .earnedSchemeInputTextt {
              background-color: #889cff;
              color: #f2f2f2;
              font-weight: 700;
              font-size: 0.7rem;
            }
          }
          .earnedSchemeLabel {
            color: #444c75;
            font-weight: 600;
            font-size: 0.85rem;
          }
          .earnedSchemeSmallLabel {
            color: #444c75;
            font-weight: 700;
            font-size: 0.8rem;
          }
          .earnedSchemeInput {
            background-color: #889cff;
            color: white;
            font-weight: 700;
            text-align: center;
            font-size: 0.9rem;
            &:focus {
              box-shadow: none !important;
            }
            &:disabled {
              background-color: #afbcf8;
              color: #6573b8;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .saveBtn {
    padding: 10px 40px;
    background-color: $color_main;
    color: #ffffff;
    &:hover {
      background-color: $color_background;
    }
  }
}
