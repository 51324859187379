@use "../../../vars/colorsCustomer" as *;

.giftPointContent {
}
.giftPointBody {
  .regFormLabel {
    color: $color_main;
  }
  .regFormInput {
    text-align: center;
    border: 2px solid $color_gray_font;
    font-weight: 700;
    color: $color_gray_font;
    &:focus {
      text-align: center;
      box-shadow: none;
      border: 2px solid $color_gray_font;
    }
  }
  h4 {
    font-size: 0.9rem;
    font-weight: 800;
    color: $color-main;
    text-align: center;
  }
  p {
    font-size: 0.8rem;
    font-weight: 700;
    color: $color_gray_font;
    text-align: center;
  }
  .okBtn {
    background-color: $color-main;
    font-size: 0.9rem;
    font-weight: 700;
    color: white;
    &:hover {
      background-color: $color-background;
      color: white;
    }
  }
}
