@use "../../../vars/colors" as *;

[dir="rtl"] * {
  .stationReportContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
      .backBtn {
        rotate: 180deg;
        cursor: pointer;
      }
    }
    .filterInputLabel {
      color: #030303;
      font-weight: 600 !important;
      font-size: 0.8rem;
    }
    .filterInputField {
      color: #94a3b8;
      min-height: 34px;
      padding: 0px 8px;
      border: 0.808081px solid #d1d5db;
      font-size: 0.9rem;
      &::placeholder {
        font-weight: 500;
        font-size: 0.75rem;
        color: #94a3b8;
        box-shadow: none;
      }
      &:focus {
        font-weight: 500;
        font-size: 0.9rem;
        color: #94a3b8;
        box-shadow: none;
      }
    }
    .filterInputCarField {
      text-align: center;
      color: #94a3b8;
      min-height: 34px;
      padding: 0px 8px;
      border: 0.808081px solid #d1d5db;
      font-size: 0.9rem;
      &::placeholder {
        font-weight: 500;
        font-size: 0.75rem;
        color: #94a3b8;
        box-shadow: none;
      }
      &:focus {
        text-align: center;
        font-weight: 500;
        font-size: 0.9rem;
        color: #94a3b8;
        box-shadow: none;
      }
    }
    .filterDateField {
      color: #94a3b8;
      width: 100% !important;
      min-height: 34px;
      padding: 0px 8px;
      border: 0.808081px solid #d1d5db;
      font-size: 0.9rem;
      &:focus {
        font-weight: 500;
        font-size: 0.85rem;
        color: #94a3b8;
        box-shadow: none;
      }
    }
    .filterInputSelect {
      color: #94a3b8;
      min-height: 34px;
      border: 0.808081px solid #d1d5db;
      font-size: 0.8rem;
      &:focus {
        font-weight: 500;
        font-size: 0.8rem;
        color: #94a3b8;
        box-shadow: none;
      }
    }
    .searchBtn {
      padding: 5px 20px;
      background-color: #0d6efd;
      font-size: 0.8rem !important;
      border-radius: 11px;
      -webkit-border-radius: 11px;
      -moz-border-radius: 11px;
      -ms-border-radius: 11px;
      -o-border-radius: 11px;
      &:hover {
        background-color: #0758d2;
      }
    }
    .resetBtn {
      padding: 8px;
      background-color: #dc3545;
      font-size: 0.8rem !important;
      border-radius: 11px;
      -webkit-border-radius: 11px;
      -moz-border-radius: 11px;
      -ms-border-radius: 11px;
      -o-border-radius: 11px;
      &:hover {
        background-color: #b72534;
      }
    }
    .downloadPdfBtn {
      background-color: #dc3545;
      font-size: 0.9rem;
      border-radius: 11px;
      -webkit-border-radius: 11px;
      -moz-border-radius: 11px;
      -ms-border-radius: 11px;
      -o-border-radius: 11px;
      &:hover {
        background-color: #b72534;
      }
      svg {
        font-size: 20px;
      }
    }
    .downloadExcelBtn {
      background-color: #28a745;
      font-size: 0.9rem;
      border-radius: 11px;
      -webkit-border-radius: 11px;
      -moz-border-radius: 11px;
      -ms-border-radius: 11px;
      -o-border-radius: 11px;
      &:hover {
        background-color: #1b8534;
      }
      svg {
        font-size: 20px;
      }
    }
  }
  .orderIdContent {
    ul {
      list-style-type: none;
      padding: 0px;
      li {
        font-size: 0.7rem;
      }
    }
  }
}
[dir="ltr"] * {
  .stationReportContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
      .backBtn {
        cursor: pointer;
      }
    }
    .filterInputLabel {
      color: #030303;
      font-weight: 600 !important;
      font-size: 0.8rem;
    }
    .filterInputField {
      color: #94a3b8;
      min-height: 34px;
      padding: 0px 8px;
      border: 0.808081px solid #d1d5db;
      font-size: 0.9rem;
      &::placeholder {
        font-weight: 500;
        font-size: 0.75rem;
        color: #94a3b8;
        box-shadow: none;
      }
      &:focus {
        font-weight: 500;
        font-size: 0.9rem;
        color: #94a3b8;
        box-shadow: none;
      }
    }
    .filterInputCarField {
      text-align: center;
      color: #94a3b8;
      min-height: 34px;
      padding: 0px 8px;
      border: 0.808081px solid #d1d5db;
      font-size: 0.9rem;
      &::placeholder {
        font-weight: 500;
        font-size: 0.75rem;
        color: #94a3b8;
        box-shadow: none;
      }
      &:focus {
        text-align: center;
        font-weight: 500;
        font-size: 0.9rem;
        color: #94a3b8;
        box-shadow: none;
      }
    }
    .filterDateField {
      color: #94a3b8;
      width: 100% !important;
      min-height: 34px;
      padding: 0px 8px;
      border: 0.808081px solid #d1d5db;
      font-size: 0.9rem;
      &:focus {
        font-weight: 500;
        font-size: 0.85rem;
        color: #94a3b8;
        box-shadow: none;
      }
    }
    .filterInputSelect {
      color: #94a3b8;
      min-height: 34px;
      border: 0.808081px solid #d1d5db;
      font-size: 0.8rem;
      &:focus {
        font-weight: 500;
        font-size: 0.8rem;
        color: #94a3b8;
        box-shadow: none;
      }
    }
    .searchBtn {
      padding: 5px 20px;
      background-color: #0d6efd;
      font-size: 0.8rem !important;
      border-radius: 11px;
      -webkit-border-radius: 11px;
      -moz-border-radius: 11px;
      -ms-border-radius: 11px;
      -o-border-radius: 11px;
      &:hover {
        background-color: #0758d2;
      }
    }
    .resetBtn {
      padding: 8px;
      background-color: #dc3545;
      font-size: 0.8rem !important;
      border-radius: 11px;
      -webkit-border-radius: 11px;
      -moz-border-radius: 11px;
      -ms-border-radius: 11px;
      -o-border-radius: 11px;
      &:hover {
        background-color: #b72534;
      }
    }
    .downloadPdfBtn {
      background-color: #dc3545;
      font-size: 0.9rem;
      border-radius: 11px;
      -webkit-border-radius: 11px;
      -moz-border-radius: 11px;
      -ms-border-radius: 11px;
      -o-border-radius: 11px;
      &:hover {
        background-color: #b72534;
      }
      svg {
        font-size: 20px;
      }
    }
    .downloadExcelBtn {
      background-color: #28a745;
      font-size: 0.9rem;
      border-radius: 11px;
      -webkit-border-radius: 11px;
      -moz-border-radius: 11px;
      -ms-border-radius: 11px;
      -o-border-radius: 11px;
      &:hover {
        background-color: #1b8534;
      }
      svg {
        font-size: 20px;
      }
    }
  }
  .orderIdContent {
    ul {
      list-style-type: none;
      padding: 0px;
      li {
        font-size: 0.7rem;
      }
    }
  }
}
