[dir="ltr"] .Toastify__toast {
  font-family: "Montserrat" !important;
  font-size: 0.9rem !important;
  font-weight: 800 !important;
  font-style: italic !important;
}

.Toastify__toast-theme--light.Toastify__toast--default {
  background: linear-gradient(
    90deg,
    rgba(28, 69, 239, 1) 17%,
    rgba(15, 36, 212, 1) 92%
  );
  color: whitesmoke;
}

.Toastify__toast--rtl {
  font-family: "IBMPlexSansArabic" !important;
  font-size: 0.9rem !important;
}

.Toastify__toast-theme--light.Toastify__toast--error {
  background: linear-gradient(
    90deg,
    rgba(245, 22, 22, 1) 17%,
    rgba(233, 17, 17, 1) 92%
  );

  color: whitesmoke !important;
}
.Toastify__toast-theme--light.Toastify__toast--success {
  background-color: #bfcaff;
  color: #5768f3 !important;
}

.Toastify__toast-icon {
  font-size: 1.4rem !important;
}

.callsContent {
  position: fixed;
  bottom: 5%;
  right: 2%;
}

.react-datepicker-wrapper {
  display: block !important;
}
