@use "../../../../../vars/colors" as *;

[dir="rtl"] * {
  .redeemReport {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
      .backBtn {
        rotate: 180deg;
        cursor: pointer;
      }
    }
  }
}

[dir="ltr"] * {
  .redeemReport {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
      .backBtn {
        cursor: pointer;
      }
    }
  }
}
