@use "../../../vars/colors" as *;

[dir="rtl"] * {
  .redeemPointsContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
      .backBtn {
        rotate: 180deg;
        cursor: pointer;
      }
    }
    .resultContent {
      padding: 15px 25px;
      background-color: $color_main;
      font-size: 0.9rem;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      div {
        h5 {
          color: white;
          font-size: 0.9rem;
          font-weight: 700;
        }
        p {
          color: white;
          font-size: 0.9rem;
          font-weight: 400;
          margin-bottom: 0px;
        }
        svg {
          color: whitesmoke;
          font-size: 20px;
        }
      }
    }
    .redeemPointsBtn {
      padding: 5px 35px;
      background-color: white !important;
      border: 1px solid $color_main;
      border-radius: 10px;
      font-weight: 400;
      font-size: 0.8rem;
      flex-grow: 1;
      color: $color_main !important;
      &:hover {
        background-color: $color_background !important;
        color: white !important;
      }
    }
    .redeemPointActiveBtn {
      background-color: $color_main !important;
      color: whitesmoke !important;
    }
  }
}

[dir="ltr"] * {
  .redeemPointsContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
      .backBtn {
        cursor: pointer;
      }
    }
    .resultContent {
      padding: 15px 25px;
      background-color: $color_main;
      font-size: 0.9rem;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      div {
        h5 {
          color: white;
          font-size: 0.9rem;
          font-weight: 700;
        }
        p {
          color: white;
          font-size: 0.9rem;
          font-weight: 400;
          margin-bottom: 0px;
        }
        svg {
          color: whitesmoke;
          font-size: 20px;
        }
      }
    }
    .redeemPointsBtn {
      padding: 5px 35px;
      background-color: white !important;
      border: 1px solid $color_main;
      border-radius: 10px;
      font-weight: 400;
      font-size: 0.8rem;
      flex-grow: 1;
      color: $color_main !important;
      &:hover {
        background-color: $color_background !important;
        color: white !important;
      }
    }
    .redeemPointActiveBtn {
      background-color: $color_main !important;
      color: whitesmoke !important;
    }
  }
}
