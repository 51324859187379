@use "../../../../vars/colors" as *;

.campaignsContent {
  .searchForm {
    width: 350px;
    max-width: 370px;
    .searchInput {
      background-color: transparent !important;
      padding: 3px 3px 3px 0px;
      border: 1.5px solid #3454f1;
      border-radius: 10px;
      .searchLabel {
        background-color: transparent !important;
        border: none !important;
        svg {
          color: $color_main !important;
          font-size: 1rem !important;
        }
      }
      .searchField {
        outline: none;
        border: none;
        border-radius: 5px !important;
        color: $color_main !important;
        font-weight: 500 !important;
        font-size: 0.8rem;
        &:focus {
          outline: none;
          box-shadow: none;
          font-weight: 600;
        }
        &::placeholder {
          color: $color_main;
          font-weight: 500 !important;
        }
      }
    }
  }
  .filterProductInput {
    background-color: $color_main !important;
    border-radius: 10px;
    padding: 5px;
    span {
      border: none;
      background-color: transparent !important;
      svg {
        color: whitesmoke;
      }
    }
  }
  .filterProductSelect {
    border-radius: 10px;
    font-size: 0.7rem;
    font-weight: 600;
    color: $color_main;
  }
  .bgActive {
    background-color: #dce2fe;
    border-radius: 15px;
    h5 {
      color: #2b4ef0;
      font-size: 0.8rem;
      font-weight: 700;
    }
    .editBtn {
      background-color: $color_main;
      font-weight: 400;
      font-size: 0.8rem;
      &:hover {
        background-color: $color_background;
      }
    }
    .bgBlue {
      background-color: #b5c1ff;
      border-radius: 10px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      .reportBtn {
        padding: 7px 30px;
        background-color: #748afc;
        font-weight: 400;
        font-size: 0.7rem;
        &:hover {
          background-color: $color_background;
        }
      }
      p {
        font-size: 0.7rem;
        font-weight: 500;
        color: #2b4ef0;
        span {
          font-size: 0.75rem;
          font-weight: 700;
          color: #2b4ef0;
        }
      }
    }
  }
  .bgNotActive {
    background-color: #f2f2f2;
    border-radius: 15px;
    h5 {
      color: #9f9f9f;
      font-size: 0.8rem;
      font-weight: 700;
    }
    .editBtn {
      background-color: $color_main;
      font-weight: 400;
      font-size: 0.8rem;
      &:hover {
        background-color: $color_background;
      }
    }
    .bgGrey {
      background-color: #dbdbdb;
      border-radius: 10px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      .reportBtn {
        padding: 7px 30px;
        background-color: #989898;
        font-weight: 400;
        font-size: 0.7rem;
        &:hover {
          background-color: $color_grey_font;
        }
      }
      p {
        font-size: 0.7rem;
        font-weight: 500;
        color: #9f9f9f;
        span {
          font-size: 0.75rem;
          font-weight: 700;
          color: #9f9f9f;
        }
      }
    }
  }
  .bgProduct {
    background-color: #f2f2f2;
    border-radius: 15px;
    h5 {
      color: #9f9f9f;
      font-size: 0.8rem;
      font-weight: 700;
    }
    .editBtn {
      background-color: $color_main;
      font-weight: 400;
      font-size: 0.8rem;
      &:hover {
        background-color: $color_background;
      }
    }
  }
}
