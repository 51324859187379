@use "../../../../vars/colors" as *;

[dir="rtl"] * {
  .addShopsActivator {
    text-align: right;
    .branchHeader {
      background-color: #dce2fe;
      .ownerInfo {
        display: block;
        font-weight: 600;
        font-size: 0.75rem;
        color: $color_grey_font;
      }
      div {
        text-align: right;
      }
      button {
        font-weight: 800 !important;
        font-size: 0.9rem;
        background-color: transparent !important;
        &:focus {
          box-shadow: none !important;
        }
        &::after {
          margin-left: 0px;
        }
      }
    }
    .branchBody {
      background-color: #dce2fe;
      div {
        text-align: right;
        .deleteBranchBtn {
          padding: 5px 15px;
          background-color: $color_dark_red;
          font-weight: 500 !important;
          font-size: 0.8rem !important;
          &:hover {
            background-color: #d1151c;
          }
        }
      }
    }
    .ownerData {
      border-bottom: 1px dashed $color_grey_font;
    }
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
    }
    .btnRight {
      text-align: right;
      button {
        padding: 10px 40px;
        background-color: $color_main;
        font-weight: 400;
        font-size: 1rem;
        &:hover {
          background-color: $color_background;
        }
      }
    }
    .addInputLabel {
      font-weight: 600;
      color: $color_main;
    }
    .addInput {
      min-height: 50px;
      border: 1px solid $color_main;
      font-weight: 400;
      color: $color_background;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .addSelect {
      min-height: 50px;
      border: 1px solid $color_main;
      font-weight: 400;
      color: white;
      background-color: $color_main;
      background-image: url("../../../../imgs/arrow-204-16.png");
      background-size: 12px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .addMultiSelect {
      min-height: 50px;
      border: 1px solid $color_main;
      padding-top: 3px;
      border-radius: 5px;
      line-height: 1.5;
      font-weight: 400;
      color: white;
      background-color: $color_main;
      background-image: url("../../../../imgs/arrow-204-16.png");
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 12px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .saveBtn {
    padding: 10px 40px;
    background-color: $color_main;
    font-weight: 400;
    font-size: 1rem;
    &:hover {
      background-color: $color_background;
    }
  }

  .addBranchBtn {
    padding: 10px 20px;
    background-color: $color_main;
    font-weight: 400;
    font-size: 0.8rem;
    &:hover {
      background-color: $color_background;
    }
  }
}
[dir="ltr"] * {
  .addShopsActivator {
    text-align: left;
    .branchHeader {
      background-color: #dce2fe;
      .ownerInfo {
        display: block;
        font-weight: 600;
        font-size: 0.75rem;
        color: $color_grey_font;
      }
      div {
        text-align: left;
      }
      button {
        font-weight: 800 !important;
        font-size: 0.9rem;
        background-color: transparent !important;
        &:focus {
          box-shadow: none !important;
        }
        &::after {
          margin-right: 0px;
        }
      }
    }
    .branchBody {
      background-color: #dce2fe;
      div {
        text-align: left;
        .deleteBranchBtn {
          padding: 5px 15px;
          background-color: $color_dark_red;
          font-weight: 500 !important;
          font-size: 0.8rem !important;
          &:hover {
            background-color: #d1151c;
          }
        }
      }
    }
    .ownerData {
      border-bottom: 1px dashed $color_grey_font;
    }
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
    }
    .btnRight {
      text-align: right;
      button {
        padding: 10px 40px;
        background-color: $color_main;
        font-weight: 400;
        font-size: 1rem;
        &:hover {
          background-color: $color_background;
        }
      }
    }
    .addInputLabel {
      font-weight: 600;
      color: $color_main;
    }
    .addInput {
      min-height: 50px;
      border: 1px solid $color_main;
      font-weight: 400;
      color: $color_background;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .addSelect {
      min-height: 50px;
      border: 1px solid $color_main;
      font-weight: 400;
      color: white;
      background-color: $color_main;
      background-image: url("../../../../imgs/arrow-204-16.png");
      background-size: 12px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .addMultiSelect {
      min-height: 50px;
      border: 1px solid $color_main;
      padding-top: 3px;
      border-radius: 5px;
      line-height: 1.5;
      font-weight: 400;
      color: white;
      background-color: $color_main;
      background-image: url("../../../../imgs/arrow-204-16.png");
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 12px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .saveBtn {
    padding: 10px 40px;
    background-color: $color_main;
    font-weight: 400;
    font-size: 1rem;
    &:hover {
      background-color: $color_background;
    }
  }

  .addBranchBtn {
    padding: 10px 20px;
    background-color: $color_main;
    font-weight: 400;
    font-size: 0.8rem;
    &:hover {
      background-color: $color_background;
    }
  }
}
