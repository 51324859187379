@use "../../../../vars/colors" as *;

[dir="rtl"] * {
  .cameraContentModal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(
      90deg,
      rgba(28, 69, 239, 1) 17%,
      rgba(15, 36, 212, 1) 92%
    );
    padding-right: 40px !important;
    padding-left: 40px !important;
    padding-top: 70px !important;
    padding-bottom: 70px !important;
    video,
    img {
      width: 100%;
      height: 30vh;
      border-radius: 10px;
      // aspect-ratio: 3/2;
      border: 2px dashed #ffffff;
    }
  }
}
[dir="ltr"] * {
  .cameraContentModal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(
      90deg,
      rgba(28, 69, 239, 1) 17%,
      rgba(15, 36, 212, 1) 92%
    );
    padding-right: 40px !important;
    padding-left: 40px !important;
    padding-top: 70px !important;
    padding-bottom: 70px !important;
    video,
    img {
      width: 100%;
      height: 30vh;
      border-radius: 10px;
      // aspect-ratio: 3/2;
      border: 2px dashed #ffffff;
    }
  }
}
