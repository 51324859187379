@use "../../../vars/colors" as *;

[dir="rtl"] .SendOtpContent {
  min-height: 100vh;
  background-color: white;
  .forgetTitle {
    font-weight: 800;
    color: $color_main;
  }
  .forgetFormContent {
    text-align: right;
    border: 2px solid $color_main;
    border-radius: 15px;
    .forgetLabel {
      font-weight: 600;
      color: $color_main;
    }
    .passLabel {
      font-weight: 600;
      color: $color_main;
    }
    .forgetInput {
      min-height: 50px;
      border: 1px solid $color_main;
      font-weight: 400;
      color: $color_background;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:disabled {
        border: none;
      }
    }
    .passInput {
      min-height: 50px;
      border: 1px solid $color_main;
      font-weight: 400;
      color: $color_background;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .forgetBtn {
      padding: 10px 40px;
      background-color: $color_main;
      font-weight: 400;
      font-size: 1rem;
      &:hover {
        background-color: $color_background;
      }
    }
  }
}
[dir="ltr"] .SendOtpContent {
  min-height: 100vh;
  background-color: white;
  .forgetTitle {
    font-weight: 800;
    color: $color_main;
  }
  .forgetFormContent {
    text-align: left;
    border: 2px solid $color_main;
    border-radius: 15px;
    .forgetLabel {
      font-weight: 600;
      color: $color_main;
    }
    .passLabel {
      font-weight: 600;
      color: $color_main;
    }
    .forgetInput {
      min-height: 50px;
      border: 1px solid $color_main;
      font-weight: 400;
      color: $color_background;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:disabled {
        border: none;
      }
    }
    .passInput {
      min-height: 50px;
      border: 1px solid $color_main;
      font-weight: 400;
      color: $color_background;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .forgetBtn {
      padding: 10px 40px;
      background-color: $color_main;
      font-weight: 400;
      font-size: 1rem;
      &:hover {
        background-color: $color_background;
      }
    }
  }
}
