@use "../vars/colors" as *;

.giftPointContent {
}
.giftPointBody {
  h4 {
    font-size: 0.8rem;
    font-weight: 800;
    color: $color_gray_font;
    text-align: center;
  }
  p {
    font-size: 0.75rem;
    font-weight: 700;
    color: #b5b5b5;
    text-align: center;
  }
  .okBtn {
    background-color: $color_dark_red;
    font-size: 0.7rem;
    font-weight: 600;
    color: white;
    &:hover {
      background-color: $color_dark_red;
      color: white;
    }
  }
}
