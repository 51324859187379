@use "../../../../../vars/_colors.scss" as *;

.addCampaignContent {
  text-align: left;
  .teamTitle {
    .backBtn {
      cursor: pointer;
    }
    font-weight: 700;
    font-size: 1.2rem;
    color: $color_main;
    text-align: left;
  }
  .addInputLabel {
    font-weight: 600;
    color: $color_main;
  }
  .addMultiSelect {
    min-height: 50px;
    border: 1px solid $color_main;
    padding-top: 3px;
    border-radius: 5px;
    line-height: 1.5;
    font-weight: 400;
    color: white;
    background-color: $color_main;
    // background-image: url("../../../../../imgs/arrow-204-16.png");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 12px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .minReqContent {
    background-color: #e7eafb;
    border-radius: 5px;
    .addInputLabel {
      font-size: 0.85rem;
      font-weight: 600;
    }
    .addInput2 {
      border: 2px solid $color_main;
      border-radius: 5px;
      padding-bottom: 5px;
      padding-top: 5px;
      background-color: #ffffff;
      .miniReqInput {
        border: none;
        background-color: transparent;
        &:focus {
          box-shadow: none;
        }
        &:disabled{
          text-align: center;
          font-weight: 600;
          font-size: 0.9rem;
          color: $color_main;
        }
      }
      .addLabel {
        background-color: #ffffff;
        border: none;
        color: $color_main!important;
        font-weight: 600!important;
        font-size: 0.85rem;
        border-left: 2px solid $color_main!important;
      }
    }
    .addInput {
      background-color: $color_main;
      padding: 10px 10px 10px 0px;
      border-radius: 7px;
      .addLabel {
        background-color: transparent !important;
        border: none !important;
        svg {
          color: white !important;
          font-size: 1rem !important;
        }
      }
      .selectInput {
        border-radius: 5px !important;
        color: $color_main;
        font-weight: 600;
        font-size: 0.8rem;
      }
    }
  }
  .voucherBtn {
    padding: 10px 20px;
    background-color: $color_main;
    color: white;
    font-weight: 500 !important;
    font-size: 0.9rem;
    min-width: 150px;
  }
}

.chipCustom {
  padding: 15px 20px !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #e7eafb;
  border-radius: 11px;
  color: $color_main;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 4px 10px;
  .closeIcon {
    cursor: pointer;
    color: #f51616 !important;
  }
}

.rewardCheck:checked + label {
  background-color: #ffffff !important;
  color: $color_main !important;
}
