@use "../../../vars/colorsCustomer" as *;

[dir="rtl"] * {
  .CustomerGiftsContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
    }
    .giftCardsParent {
      background-color: white;
      border-radius: 20px;
      margin: 10px;
      .giftCard {
        background-color: #ffe6e6;
        border-radius: 10px;
        width: 400px;
        .giftCardInfo {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          .giftCardImg {
            background-color: #f5f5f5;
            padding: 10px;
            min-width: 70px;
            width: 70px;
            height: 70px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 100%;
              max-width: 60px;
              height: auto;
              object-fit: contain;
            }
          }
          .giftCardDetails {
            p {
              color: $color_main;
              font-weight: 500;
              font-size: 0.8rem;
              span {
                font-weight: 700;
                font-size: 0.85rem;
              }
            }
          }
        }
        .giftCardBtns {
          display: flex;
          flex-direction: row;
          .getCodeBtn {
            flex-grow: 1;
            background-color: $color_main;
            font-weight: 500;
            font-size: 0.8rem;
            &:hover {
              background-color: $color_background;
            }
            &:disabled {
              background-color: $color_secondary !important;
              color: $color_gray_font;
              border: none;
              font-weight: 600;
            }
          }
          .deleteBtn {
            flex-grow: 1;
            background-color: #f51616;
            font-weight: 500;
            font-size: 0.8rem;
            &:hover {
              background-color: #a70909;
            }
          }
        }
      }
    }
    .redeemPointsBtn {
      min-width: 100%;
      padding: 5px 35px;
      background-color: #f5f5f5 !important;
      border: 1px solid $color_main;
      border-radius: 10px;
      font-weight: 400;
      font-size: 0.8rem;
      flex-grow: 1;
      color: $color_main !important;
      &:hover {
        background-color: $color_background !important;
        color: white !important;
      }
    }
    .redeemPointActiveBtn {
      background-color: $color_main !important;
      color: whitesmoke !important;
    }
  }

  @media (max-width: 589px) {
    .giftCard {
      min-width: 100% !important;
    }
  }

  @media (max-width: 342px) {
    .giftCardImg {
      margin-bottom: 10px;
    }
  }
}

[dir="ltr"] * {
  .CustomerGiftsContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
    }
    .giftCardsParent {
      background-color: white;
      border-radius: 20px;
      margin: 10px;
      .giftCard {
        background-color: #ffe6e6;
        border-radius: 10px;
        flex-grow: 1;
        width: 250px;
        max-width: 400px;
        .giftCardInfo {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          .giftCardImg {
            background-color: #f5f5f5;
            padding: 10px;
            min-width: 70px;
            width: 70px;
            height: 70px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 100%;
              max-width: 60px;
              height: auto;
              object-fit: contain;
            }
          }
          .giftCardDetails {
            p {
              color: $color_main;
              font-weight: 500;
              font-size: 0.8rem;
              span {
                font-weight: 700;
                font-size: 0.85rem;
              }
            }
          }
        }
        .giftCardBtns {
          display: flex;
          flex-direction: row;
          .getCodeBtn {
            flex-grow: 1;
            background-color: $color_main;
            font-weight: 500;
            font-size: 0.8rem;
            &:hover {
              background-color: $color_background;
            }
            &:disabled {
              background-color: $color_secondary !important;
              color: $color_gray_font;
              border: none;
              font-weight: 600;
            }
          }
          .deleteBtn {
            flex-grow: 1;
            background-color: #f51616;
            font-weight: 500;
            font-size: 0.8rem;
            &:hover {
              background-color: #a70909;
            }
          }
        }
      }
    }
    .redeemPointsBtn {
      min-width: 100%;
      padding: 5px 35px;
      background-color: #f5f5f5 !important;
      border: 1px solid $color_main;
      border-radius: 10px;
      font-weight: 400;
      font-size: 0.8rem;
      flex-grow: 1;
      color: $color_main !important;
      &:hover {
        background-color: $color_background !important;
        color: white !important;
      }
    }
    .redeemPointActiveBtn {
      background-color: $color_main !important;
      color: whitesmoke !important;
    }
  }

  @media (max-width: 589px) {
    .giftCard {
      min-width: 100% !important;
    }
  }

  @media (max-width: 342px) {
    .giftCardImg {
      margin-bottom: 10px;
    }
  }
}
