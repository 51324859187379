@use "../../../vars/colors" as *;

[dir="rtl"] * {
  .productsContent {
    text-align: right;
    .btnSaveGroup {
      text-align: left;
      .saveBtn {
        padding: 10px 40px;
        background-color: $color_main;
        font-weight: 400;
        font-size: 0.9rem;
        &:hover {
          background-color: $color_background;
        }
      }
      .reportBtn {
        padding: 10px 40px;
        background-color: #858585;
        font-weight: 400;
        font-size: 0.9rem;
        color: white;
        &:hover {
          background-color: $color_light;
        }
      }
    }
    .productBtn {
      min-width: 175px;
      padding: 5px 35px;
      background-color: white !important;
      border: 1px solid $color_main;
      border-radius: 10px;
      font-weight: 400;
      font-size: 0.8rem;
      flex-grow: 1;
      color: $color_main !important;
      &:hover {
        background-color: $color_background !important;
        color: white !important;
      }
    }
    .productBtnActive {
      background-color: $color_main !important;
      color: whitesmoke !important;
    }
    .productTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
    }
  }

  @media (max-width: 458px) {
    .productBtn {
      margin-bottom: 10px;
    }
  }
}

[dir="ltr"] * {
  .productsContent {
    text-align: left;
    .btnSaveGroup {
      text-align: right;
      .saveBtn {
        padding: 10px 40px;
        background-color: $color_main;
        font-weight: 400;
        font-size: 0.9rem;
        &:hover {
          background-color: $color_background;
        }
      }
      .reportBtn {
        padding: 10px 40px;
        background-color: #858585;
        font-weight: 400;
        font-size: 0.9rem;
        color: white;
        &:hover {
          background-color: $color_light;
        }
      }
    }
    .productBtn {
      min-width: 175px;
      padding: 5px 35px;
      background-color: white !important;
      border: 1px solid $color_main;
      border-radius: 10px;
      font-weight: 400;
      font-size: 0.8rem;
      flex-grow: 1;
      color: $color_main !important;
      &:hover {
        background-color: $color_background !important;
        color: white !important;
      }
    }
    .productBtnActive {
      background-color: $color_main !important;
      color: whitesmoke !important;
    }
    .productTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
    }
  }

  @media (max-width: 458px) {
    .productBtn {
      margin-bottom: 10px;
    }
  }
}
