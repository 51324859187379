@use "../../../../vars/colorsCustomer" as *;

[dir="rtl"] * {
  .teamTitle {
    font-weight: 700;
    font-size: 1.2rem;
    color: $color_main;
    text-align: right;
  }
  .giftCardsParent {
    background-color: white;
    border-radius: 20px;
    margin: 10px;
    .fuelTypeContent {
      .fuelTypeBtn {
        border: none;
        background-color: transparent;
        color: $color_gray_font;
        font-weight: 700;
      }
      .fuelTypeActiveBtn {
        color: $color_main;
        font-weight: 700;
        border-bottom: 2px solid $color_main;
      }
    }
    .giftCard {
      background-color: #ffe6e6;
      border-radius: 10px;
      width: 400px;
      position: relative;
      .notActiveCard {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(242, 242, 242, 0.6) !important;
        border-radius: 10px;
        z-index: 10;
      }
      .giftCardInfo {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .giftCardImg {
          background-color: #f5f5f5;
          padding: 10px;
          min-width: 70px;
          width: 70px;
          height: 70px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            max-width: 60px;
            height: auto;
            object-fit: contain;
          }
        }
        .giftCardDetails {
          p {
            color: $color_main;
            font-weight: 500;
            font-size: 0.8rem;
            span {
              font-weight: 700;
              font-size: 0.85rem;
            }
          }
        }
      }
      .giftCardBtns {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .notSettledStatus {
          background-color: #a3a1a2;
          color: white;
          font-size: 0.75rem;
          font-weight: 800;
          border-radius: 15px;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
        }
        .getCodeBtn {
          flex-grow: 1;
          background-color: $color_main;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_background;
          }
          &:disabled {
            background-color: $color_secondary !important;
            color: $color_gray_font;
            border: none;
            font-weight: 600;
          }
        }
        .deleteBtn {
          flex-grow: 1;
          background-color: #f51616;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: #a70909;
          }
        }
      }
    }
  }

  @media (max-width: 589px) {
    .giftCard {
      min-width: 100% !important;
    }
  }

  @media (max-width: 342px) {
    .giftCardImg {
      margin-bottom: 10px;
    }
  }
}

[dir="ltr"] * {
  .teamTitle {
    font-weight: 700;
    font-size: 1.2rem;
    color: $color_main;
    text-align: left;
  }
  .giftCardsParent {
    background-color: white;
    border-radius: 20px;
    margin: 10px;
    .fuelTypeContent {
      .fuelTypeBtn {
        border: none;
        background-color: transparent;
        color: $color_gray_font;
        font-weight: 700;
      }
      .fuelTypeActiveBtn {
        color: $color_main;
        font-weight: 700;
        border-bottom: 2px solid $color_main;
      }
    }
    .giftCard {
      background-color: #ffe6e6;
      border-radius: 10px;
      flex-grow: 1;
      width: 250px;
      max-width: 400px;
      position: relative;
      .notActiveCard {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(242, 242, 242, 0.6) !important;
        border-radius: 10px;
        z-index: 10;
      }
      .giftCardInfo {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .giftCardImg {
          background-color: #f5f5f5;
          padding: 10px;
          min-width: 70px;
          width: 70px;
          height: 70px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            max-width: 60px;
            height: auto;
            object-fit: contain;
          }
        }
        .giftCardDetails {
          p {
            color: $color_main;
            font-weight: 500;
            font-size: 0.8rem;
            span {
              font-weight: 700;
              font-size: 0.85rem;
            }
          }
        }
      }
      .giftCardBtns {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .notSettledStatus {
          background-color: #a3a1a2;
          color: white;
          font-size: 0.75rem;
          font-weight: 800;
          border-radius: 15px;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
        }
        .getCodeBtn {
          flex-grow: 1;
          background-color: $color_main;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: $color_background;
          }
          &:disabled {
            background-color: $color_secondary !important;
            color: $color_gray_font;
            border: none;
            font-weight: 600;
          }
        }
        .deleteBtn {
          flex-grow: 1;
          background-color: #f51616;
          font-weight: 500;
          font-size: 0.8rem;
          &:hover {
            background-color: #a70909;
          }
        }
      }
    }
  }

  @media (max-width: 589px) {
    .giftCard {
      min-width: 100% !important;
    }
  }

  @media (max-width: 342px) {
    .giftCardImg {
      margin-bottom: 10px;
    }
  }
}
