@use "../../../vars/colors" as *;

[dir="rtl"] * {
  .activatorShop {
    .shopTitle {
      text-align: right!important;
    }
    text-align: left;
    .btnAssignProspect {
      text-align: right;
      .assignProspectBtn {
        padding: 15px 30px;
        background-color: $color_main;
        font-weight: 400;
        font-size: 0.9rem;
        &:hover {
          background-color: $color_background;
        }
      }
    }
    .filterForm {
      width: 290px;
      max-width: 300px;
      .filterInput {
        background-color: $color_main;
        padding: 10px 10px 10px 0px;
        border-radius: 7px;
        .filterLabel {
          background-color: transparent !important;
          border: none !important;
          svg {
            color: white !important;
            font-size: 1rem !important;
          }
        }
        .filterSelect {
          border-radius: 5px !important;
          color: $color_main;
          font-weight: 600;
          font-size: 0.8rem;
        }
      }
    }
    .searchForm {
      width: 350px;
      max-width: 370px;
      .searchInput {
        background-color: transparent !important;
        padding: 3px 3px 3px 0px;
        border: 1.5px solid #3454f1;
        border-radius: 10px;
        .searchLabel {
          background-color: transparent !important;
          border: none !important;
          svg {
            color: $color_main !important;
            font-size: 1rem !important;
          }
        }
        .searchField {
          outline: none;
          border: none;
          border-radius: 5px !important;
          color: $color_main !important;
          font-weight: 500 !important;
          font-size: 0.8rem;
          &:focus {
            outline: none;
            box-shadow: none;
            font-weight: 600;
          }
          &::placeholder {
            color: $color_main;
            font-weight: 500 !important;
          }
        }
      }
    }

    .shopTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
    }
    .assignBtn,
    .activateBtn {
      padding: 8px 30px;
      background-color: #ffff;
      font-weight: 500;
      font-size: 0.9rem;
      border-radius: 15px;
      color: $color_main;
      &:hover {
        background-color: $color_background;
        color: #ffff;
      }
    }
  }
}
[dir="ltr"] * {
  .activatorShop {
    text-align: left;
    .btnAssignProspect {
      text-align: right;
      .assignProspectBtn {
        padding: 15px 30px;
        background-color: $color_main;
        font-weight: 400;
        font-size: 0.9rem;
        &:hover {
          background-color: $color_background;
        }
      }
    }
    .filterForm {
      width: 290px;
      max-width: 300px;
      .filterInput {
        background-color: $color_main;
        padding: 10px 10px 10px 0px;
        border-radius: 7px;
        .filterLabel {
          background-color: transparent !important;
          border: none !important;
          svg {
            color: white !important;
            font-size: 1rem !important;
          }
        }
        .filterSelect {
          border-radius: 5px !important;
          color: $color_main;
          font-weight: 600;
          font-size: 0.8rem;
        }
      }
    }
    .searchForm {
      width: 350px;
      max-width: 370px;
      .searchInput {
        background-color: transparent !important;
        padding: 3px 3px 3px 0px;
        border: 1.5px solid #3454f1;
        border-radius: 10px;
        .searchLabel {
          background-color: transparent !important;
          border: none !important;
          svg {
            color: $color_main !important;
            font-size: 1rem !important;
          }
        }
        .searchField {
          outline: none;
          border: none;
          border-radius: 5px !important;
          color: $color_main !important;
          font-weight: 500 !important;
          font-size: 0.8rem;
          &:focus {
            outline: none;
            box-shadow: none;
            font-weight: 600;
          }
          &::placeholder {
            color: $color_main;
            font-weight: 500 !important;
          }
        }
      }
    }

    .shopTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
    }
    .assignBtn,
    .activateBtn {
      padding: 8px 30px;
      background-color: #ffff;
      font-weight: 500;
      font-size: 0.9rem;
      border-radius: 15px;
      color: $color_main;
      &:hover {
        background-color: $color_background;
        color: #ffff;
      }
    }
  }
}
