@use "../../../../../vars/colors" as *;

[dir="rtl"] * {
  .ProductReportContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
      .backBtn {
        rotate: 180deg;
        cursor: pointer;
      }
    }
  }
  .governModal {
    .governContent {
      background-color: white;
      border-radius: 10px;
      h6 {
        font-weight: 700;
        color: $color_background;
      }
      .noData {
        display: block;
        text-align: center;
        color: $color_background;
        background-color: #f2f2f2;
        font-weight: 700;
        font-size: 0.9rem;
      }
      div {
        display: inline;
        border-radius: 7px;
        font-size: 1rem;
        background-color: $color_main;
        span {
          background-color: transparent !important;
          font-weight: 500 !important;
        }
        .countGoverns {
          font-size: 0.8rem;
          font-weight: 800 !important;
          color: white !important;
        }
      }
    }
  }
  .governModal:has(> div) {
    background-color: $color_main;
    border-radius: 5px;
  }
  .showBtn {
    background-color: #748afc !important;
    &:hover {
      background-color: $color_main;
    }
  }
}
[dir="ltr"] * {
  .ProductReportContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
      .backBtn {
        cursor: pointer;
      }
    }
  }
  .governModal {
    .governContent {
      background-color: white;
      border-radius: 10px;
      h6 {
        font-weight: 700;
        color: $color_background;
      }
      .noData {
        display: block;
        text-align: center;
        color: $color_background;
        background-color: #f2f2f2;
        font-weight: 700;
        font-size: 0.9rem;
      }
      div {
        display: inline;
        border-radius: 7px;
        font-size: 1rem;
        background-color: $color_main;
        span {
          background-color: transparent !important;
          font-weight: 500 !important;
        }
        .countGoverns {
          font-size: 0.8rem;
          font-weight: 800 !important;
          color: white !important;
        }
      }
    }
  }
  .governModal:has(> div) {
    background-color: #748afc;
    border-radius: 5px;
  }
  .showBtn {
    background-color: #748afc !important;
    &:hover {
      background-color: $color_main;
    }
  }
}
