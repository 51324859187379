@use "../../../../../vars/colors" as *;

[dir="rtl"] * {
  .activatedContent {
    .bgBlue {
      background-color: #dce2fe;
      border-radius: 15px;
      h4 {
        color: $color_main;
        font-weight: 700;
        font-size: 1.1rem;
      }
      .infoContent {
        h5,
        p,
        span {
          color: $color_main;
        }
        h5 {
          font-weight: 700;
          font-size: 1.1rem;
        }
        p,
        span {
          font-weight: 500;
          font-size: 0.9rem;
        }
      }
      .verifyIcon {
        font-size: 30px;
        color: $color_main;
      }
    }
  }
}

[dir="ltr"] {
  .activatedContent {
    .bgBlue {
      background-color: #dce2fe;
      border-radius: 15px;
      h4 {
        color: $color_main;
        font-weight: 700;
        font-size: 1.1rem;
      }
      .infoContent {
        h5,
        p,
        span {
          color: $color_main;
        }
        h5 {
          font-weight: 700;
          font-size: 1.1rem;
        }
        p,
        span {
          font-weight: 500;
          font-size: 0.9rem;
        }
      }
      .verifyIcon {
        font-size: 30px;
        color: $color_main;
      }
    }
  }
}
