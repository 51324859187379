@use "../../../vars/colors" as *;

[dir="rtl"] * {
  .reportsHomeContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
      .backBtn {
        rotate: 180deg;
        cursor: pointer;
      }
    }
    .btnsContent {
      display: flex;
      justify-content: center;
      align-items: center;
      .activestationsReportBtn {
        min-width: 25%;
        padding: 5px 35px;
        font-size: 0.75rem;
        font-weight: 500;
        background-color: $color_main;
        color: white;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        &:hover {
          background-color: $color_background;
        }
      }
      .notactiveStationReportBtn {
        min-width: 25%;
        padding: 5px 35px;
        font-size: 0.75rem;
        font-weight: 500;
        background-color: $color_main;
        color: white;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        opacity: 0.2;
      }
    }
  }
}

[dir="ltr"] * {
  .reportsHomeContent {
    .teamTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
      .backBtn {
        cursor: pointer;
      }
    }
    .btnsContent {
      display: flex;
      justify-content: center;
      align-items: center;
      .activestationsReportBtn {
        min-width: 25%;
        padding: 5px 35px;
        font-size: 0.75rem;
        font-weight: 500;
        background-color: $color_main;
        color: white;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        &:hover {
          background-color: $color_background;
        }
      }
      .notactiveStationReportBtn {
        min-width: 25%;
        padding: 5px 35px;
        font-size: 0.75rem;
        font-weight: 500;
        background-color: $color_main;
        color: white;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        opacity: 0.2;
      }
    }
  }
}
