@use "../../../../vars/colors" as *;

[dir="rtl"] * {
  .reportActivator {
    text-align: right;
    .teamTitle {
      .backBtn {
        rotate: 180deg;
        cursor: pointer;
      }
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
    }
    .assignBtn,
    .activateBtn {
      padding: 8px 30px;
      background-color: #ffff;
      font-weight: 500;
      font-size: 0.75rem;
      border-radius: 15px;
      color: $color_main;
      &:hover {
        background-color: $color_background;
        color: #ffff;
      }
    }
  }

  @media (max-width: 470px) {
    .assignBtn,
    .activateBtn {
      width: 100%;
    }
  }
}

[dir="ltr"] * {
  .reportActivator {
    text-align: left;
    .teamTitle {
      .backBtn {
        cursor: pointer;
      }
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
    }
    .assignBtn,
    .activateBtn {
      padding: 8px 30px;
      background-color: #ffff;
      font-weight: 500;
      font-size: 0.75rem;
      border-radius: 15px;
      color: $color_main;
      &:hover {
        background-color: $color_background;
        color: #ffff;
      }
    }
  }

  @media (max-width: 470px) {
    .assignBtn,
    .activateBtn {
      width: 100%;
    }
  }
}
