@use "../../../vars/colors" as *;

[dir="rtl"] * {
  .OwnerDetailContent {
    .DashboardTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: right;
    }
    .btnRight {
      text-align: left;
      button {
        padding: 15px 20px;
        background-color: $color_main;
        font-weight: 400;
        font-size: 0.9rem;
        &:hover {
          background-color: $color_background;
        }
      }
    }
    .shopTitle {
      h4 {
        font-weight: 800 !important;
        color: $color_main;
      }
      h6 {
        font-weight: 400;
        color: $color_light !important;
      }
    }
    .infoContent {
      border: 2px solid $color_main;
      border-radius: 15px;
      .ownerChar {
        width: 80px;
        height: auto;
        object-fit: contain !important;
        border: 2px solid $color_main;
        border-radius: 50%;
      }
      .ownerTitle {
        text-align: right;
        h4 {
          font-size: 0.9rem;
          color: $color_main;
        }
        h5 {
          font-weight: 700;
          color: $color_main;
          font-size: 1rem;
        }
        h6 {
          font-weight: 400;
          color: $color_main;
        }
      }
    }
    .contactInfo {
      background-color: $color_secondary;
      border-radius: 15px;
      text-align: right;
      .contactTitle {
        font-weight: 700;
        font-size: 1.2rem;
        color: $color_light;
      }
      .element {
        h5 {
          display: inline-block;
          width: 50%;
          min-width: 50%;
          font-size: 1rem;
          font-weight: 500;
          color: $color_light;
        }
      }
    }
    .locationInfo {
      background-color: $color_secondary;
      border-radius: 15px;
      text-align: left;
      .location {
        font-weight: 700;
        font-size: 1.2rem;
        color: $color_light;
      }
      h5 {
        font-size: 1rem;
        font-weight: 500;
        color: $color_light;
      }
      .selectBranchList {
        background-color: #a4a4a4;
        color: white;
        font-weight: 700;
        font-size: 0.95rem;
        &:focus {
          box-shadow: none;
          border: none;
        }
      }
    }
  }

  .ownerBranchesContent {
    h4 {
      font-weight: 800;
      font-size: 1rem;
      color: #a4a4a4;
    }
    .element {
      h5 {
        font-weight: 500;
        font-size: 0.8rem;
      }
      span {
        font-weight: 700 !important;
        font-size: 1rem;
        color: $color_light;
      }
    }
  }

  option {
    &:hover {
      background-color: aqua !important;
    }
  }
}

[dir="ltr"] * {
  .OwnerDetailContent {
    .DashboardTitle {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
      text-align: left;
    }
    .btnRight {
      text-align: right;
      button {
        padding: 15px 20px;
        background-color: $color_main;
        font-weight: 400;
        font-size: 0.9rem;
        &:hover {
          background-color: $color_background;
        }
      }
    }
    .shopTitle {
      h4 {
        font-weight: 800 !important;
        color: $color_main;
      }
      h6 {
        font-weight: 400;
        color: $color_light !important;
      }
    }
    .infoContent {
      border: 2px solid $color_main;
      border-radius: 15px;
      .ownerChar {
        width: 80px;
        height: auto;
        object-fit: contain !important;
        border: 2px solid $color_main;
        border-radius: 50%;
      }
      .ownerTitle {
        text-align: left;
        h4 {
          font-size: 0.9rem;
          color: $color_main;
        }
        h5 {
          font-weight: 700;
          color: $color_main;
          font-size: 1rem;
        }
        h6 {
          font-weight: 400;
          color: $color_main;
        }
      }
    }
    .contactInfo {
      background-color: $color_secondary;
      border-radius: 15px;
      text-align: left;
      .contactTitle {
        font-weight: 700;
        font-size: 1.2rem;
        color: $color_light;
      }
      .element {
        h5 {
          display: inline-block;
          width: 50%;
          min-width: 50%;
          font-size: 1rem;
          font-weight: 500;
          color: $color_light;
        }
      }
    }
    .locationInfo {
      background-color: $color_secondary;
      border-radius: 15px;
      text-align: left;
      .location {
        font-weight: 700;
        font-size: 1.2rem;
        color: $color_light;
      }
      h5 {
        font-size: 1rem;
        font-weight: 500;
        color: $color_light;
      }
      .selectBranchList {
        background-color: #a4a4a4;
        color: white;
        font-weight: 700;
        font-size: 0.95rem;
        &:focus {
          box-shadow: none;
          border: none;
        }
      }
    }
  }

  .ownerBranchesContent {
    h4 {
      font-weight: 800;
      font-size: 1rem;
      color: #a4a4a4;
    }
    .element {
      h5 {
        font-weight: 500;
        font-size: 0.8rem;
      }
      span {
        font-weight: 700 !important;
        font-size: 1rem;
        color: $color_light;
      }
    }
  }

  option {
    &:hover {
      background-color: aqua !important;
    }
  }
}
