.container * {
  font-family: "Arial", sans-serif !important;
  direction: rtl; /* Right-to-Left text direction */
  line-height: 1.6;
}
.container {
  max-width: 800px;
  margin: 20px auto!important;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

h1 {
  text-align: center; /* Center the title */
  font-size: 2.5em; /* Adjust font size as needed */
  font-weight: bold; /* Make the title bold */
  margin-bottom: 30px; /* Add some spacing below the title */
  margin-top: 30px; /* Add some spacing below the title */
}

h2,
h3 {
  /* Style for headings */
  margin-top: 25px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 1.5em;
}

p {
  margin-bottom: 15px;
  text-align: justify; /* Justify text alignment */
}

ul,
ol {
  margin-bottom: 15px;
  padding-right: 25px;
}

a {
  /* Style for links */
  color: #007bff; /* Example blue color */
  text-decoration: none;
}
